import React from "react";
import gsap from "gsap";
import { isMobile } from "detect-touch-device";

import artika from "../images/artika.png";
import menu from "../images/menu.png";
import close from "../images/close.png";
import galleryIco from "../images/gallery-ico.svg";
import mouseIco from "../images/mouse_1.svg";
import pointFloor from "../images/puntos-piso.svg";
import videoPlay from "../images/video-play.svg";
import nextTut from "../images/next-tut.svg";
import prevTut from "../images/prev-tut.svg";
import handCursor from "../images/hand-cursor.svg";
import rotationScreen from "../images/rotation-screen.png";

class TutorialModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: "flex",
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  closeModal = (event) => {
    if (event != null) {
      event.preventDefault();
    }
    this.setState({
      displayModal: "none",
    });
    this.props["start-scene-controls"]();
    this.gtmSendEvent(null, {
      event: "click",
      action_name: "gallery-tutorial-close-btn",
    });
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    return (
      <>
        <div
          className="container-modal"
          style={{ display: this.state.displayModal }}
        >
          <div className="box-modal">
            <h2 style={{ display: isMobile ? "none" : "flex" }}>
              ¡Bienvenido a Los Sueños de Frida Kahlo!
            </h2>

            <div
              className="box-modal-container-rotation-screen"
              style={{ display: isMobile ? "flex" : "none" }}
            >
              <img src={rotationScreen}></img>
              <span>
                Navega en horizontal
                <br />
                para mejorar
                <br />
                tu experiencia.
              </span>
            </div>

            <div
              className="box-modal-container-items"
              style={{ display: isMobile ? "none" : "flex" }}
            >
              <div className="box-modal-item">
                <img src={mouseIco}></img>
                <p>Haz clic y arrastra el mouse para mirar alrededor.</p>
              </div>
              <div className="box-modal-item">
                <img src={pointFloor}></img>
                <p>
                  Utiliza los botones para avanzar y retroceder entre los puntos
                  de interés.
                </p>
              </div>
              <div className="box-modal-item">
                <img src={videoPlay}></img>
                <p>
                  Reproduce los videos en los muros haciendo click sobre ellos.
                </p>
              </div>
            </div>

            <a href="#" onClick={this.closeModal} className="modal-close-btn">
              <img src={close}></img>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default TutorialModal;
