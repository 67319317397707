import React from "react";
import gsap from "gsap";
import artika from "../images/artika.png";
import menu from "../images/menu.png";
import close from "../images/close.png";
import galleryIco from "../images/gallery-ico.svg";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: "none",
    };
    this.isMenuOpened = false;
  }

  componentDidMount() {}

  componentDidUpdate() {}

  openMenu = () => {
    this.isMenuOpened = true;
    this.setState({
      displayMenu: "flex",
    });
    window.dataLayer.push({
      event: "click",
      action_name: "menu-open-btn",
    });
  };

  closeMenu = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.isMenuOpened = false;
    this.setState({
      displayMenu: "none",
    });
    window.dataLayer.push({
      event: "click",
      action_name: "menu-close-btn",
    });
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    return (
      <>
        <div
          className="container-menu"
          style={{ display: this.state.displayMenu }}
        >
          <div className="box-menu">
            <ul>
              {/* <li>
                <a
                  href="/"
                  onClick={(e) => {
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-home-btn",
                    });
                  }}
                >
                  Home
                </a>
              </li> */}
              {/* <li>
                <a
                  href="/"
                  onClick={(e) => {
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-hall-btn",
                    });
                  }}
                >
                  Sala Central
                </a>
              </li> */}
              <li>
                <a
                  href="/frida"
                  onClick={(e) => {
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-frida-btn",
                    });
                  }}
                >
                  Sala Frida
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-frida-ficha-btn",
                    });
                    let section = this.props["get-current-section"]();
                    if (section == 0) {
                      window.location.href = "/fridaficha/product.php";
                    } else {
                      window.location.href =
                        "/fridaficha/product.php?section=" + section;
                    }
                  }}
                >
                  Detalles sobre <br />
                  Los Sueños <br />
                  de Frida Kahlo
                </a>
              </li>
              <li>
                <a
                  href="/botero"
                  onClick={(e) => {
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-botero-btn",
                    });
                  }}
                >
                  Sala Botero
                </a>
              </li>
              {/* <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-botero-ficha-btn",
                    });
                    let section = this.props["get-current-section"]();
                    if (section == 0) {
                      window.location.href = "/boteroficha/product.php";
                    } else {
                      window.location.href =
                        "/boteroficha/product.php?section=" + section;
                    }
                  }}
                >
                  Detalles sobre <br />
                  Vía Crucis <br />
                  de Fernando Botero
                </a>
              </li> */}
              {/* <li className="enabled-false">
                <a
                  href="#"
                  onClick={(e) => {
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-sabina-btn",
                    });
                  }}
                >
                  Sala Sabina <span>(próximamente)</span>
                </a>
              </li> */}
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.closeMenu();
                    var path = window.location.pathname;
                    if (path.includes("botero")) {
                      this.props["open-form"](2);
                    } else {
                      this.props["open-form"]();
                    }
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "menu-help-btn",
                    });
                  }}
                >
                  Consigue tu obra
                </a>
              </li>
            </ul>
            <a href="#" onClick={this.closeMenu} className="menu-close-btn">
              <img src={close}></img>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Menu;
