import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
// import { Controller, Scene } from "react-scrollmagic";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";

import artika from "../images/artika.png";
import menu from "../images/menu.png";
import close from "../images/close-menu.svg";
import galleryIco from "../images/gallery-ico.png";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scroll = null;
    this.args = null;
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "pageview",
      pageName: "home",
      pageType: "landing",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });

    this.scroll = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      resetNativeScroll: true,
    });
    this.scroll.on("scroll", this.onScroll);
    this.onScroll({
      limit: { y: 0 },
      scroll: { y: 0 },
    });
    setTimeout(() => {
      this.props["hide-loader"](2, 0);
    }, 2000);
  }

  onScroll = (args) => {
    this.args = args;
  };

  componentDidUpdate() {}

  onClickMenuBtn = (event) => {
    event.preventDefault();
    this.props["open-menu"]();
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div className="container-fluid container-home" data-scroll-container>
          <div className="container">
            <div className="row container-header">
              <div className="col">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "home-artika-btn",
                    });
                    window.location.href = "/";
                  }}
                >
                  <img className="artika-logo" src={artika}></img>
                </a>
              </div>
              <div className="col text-right">
                <a href="#" onClick={this.onClickMenuBtn}>
                  <img className="home-menu" src={menu}></img>
                </a>
              </div>
            </div>
            <div className="row container-s1">
              <h1 className="col-md-6">
                <p className="p-1">Los sueños de</p>
                <p className="p-2">FRIDA KAHLO</p>
              </h1>
              <div className="col-md-6 container-btn-tienda">
                <a
                  className="btn-tienda"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.gtmSendEvent(e, {
                      event: "click",
                      action_name: "home-help-btn",
                    });
                    this.props["open-form"]();
                  }}
                >
                  Consigue tu obra
                </a>
              </div>
            </div>
          </div>

          <div className="container-fluid container-s2">
            <div className="row">
              <div className="col">
                <div className="container">
                  <div className="row">
                    <div
                      className="col container-btn-gallery"
                      data-scroll-section
                      data-scroll
                      data-scroll-speed="-5"
                      data-scroll-repeat
                    >
                      {/* <Controller>
                        <Scene
                          duration={100}
                          enabled={isMobile ? false : true}
                          pin
                        ></Scene>
                      </Controller> */}
                      <a
                        className="btn-gallery"
                        href="/hall"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props["show-loader"](2, 0, "block", () => {
                            this.props.navigation("/hall");
                          });
                          this.gtmSendEvent(e, {
                            event: "click",
                            action_name: "home-gallery-btn",
                          });
                        }}
                      >
                        <span>
                          <img width="102" src={galleryIco}></img>
                        </span>
                        <span>
                          Explora <br />
                          la galería virtual
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container container-s3">
            <div className="row">
              <div className="col">
                <div className="container-btn-tienda-1">
                  <a
                    className="btn-tienda"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.gtmSendEvent(e, {
                        event: "click",
                        action_name: "home-help-btn",
                      });
                      this.props["open-form"]();
                    }}
                  >
                    Consigue tu obra
                  </a>
                </div>
                <p>
                  Un objeto artístico que reúne por primera vez todos los
                  dibujos de la artista repartidos por todo el mundo. Sumérgete
                  en su galería virtual para conocer las obras que contiene y su
                  exclusivo proceso de elaboración.
                </p>
              </div>
            </div>
          </div>

          <div className="container-fluid container-s4">
            <div className="row">
              <div className="col">
                <p>
                  <span>ARTIKA.</span> Una colección de objetos artísticos que
                  son mucho más que libros.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Home {...props} navigation={navigation} />;
}
