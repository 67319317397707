import React from "react";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import ModalVideo from "../components/ModalVideo.js";
import LocomotiveScroll from "locomotive-scroll";
import fichaFridaStyles from "../css/ficha-frida-styles.css";
import artika from "../images/artika.png";
import menu from "../images/menu.png";
import close from "../images/close.png";
import galleryIco from "../images/gallery-ico.svg";

class FichaFrida extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scroll = null;
    this.args = null;
    this.containerModal = React.createRef();
    this.modalVideo = React.createRef();
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "pageview",
      pageName: "ficha frida",
      pageType: "galeria virtual",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });
    // this.scroll = new LocomotiveScroll({
    //   el: document.querySelector("[data-scroll-container]"),
    //   smooth: true,
    //   resetNativeScroll: true,
    // });
    // this.scroll.on("scroll", this.onScroll);
    // this.onScroll({
    //   limit: { y: 0 },
    //   scroll: { y: 0 },
    // });
    setTimeout(() => {
      this.props["hide-loader"](2, 0);
    }, 2000);
  }

  onScroll = (args) => {
    this.args = args;
  };

  componentDidUpdate() {}

  onClickMenuBtn = (event) => {
    event.preventDefault();
    this.props["open-menu"]();
  };

  onCloseModalVideoCompletion = () => {};

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    return (
      <>
        <div
          className="container-ficha"
          data-scroll-container
          ref={this.containerModal}
        >
          <div className="container-fluid-ficha container-fluid">
            {/* Header */}
            <div className="container">
              <div className="row container-header">
                <div className="col">
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.gtmSendEvent(e, {
                        event: "click",
                        action_name: "frida-ficha-artika-btn",
                      });
                      window.location.href = "/";
                    }}
                  >
                    <img className="artika-logo" src={artika}></img>
                  </a>
                </div>
                <div className="col text-right">
                  <a href="#" onClick={this.onClickMenuBtn}>
                    <img className="home-menu" src={menu}></img>
                  </a>
                </div>
              </div>
            </div>
            {/* Section 1 */}
            <div className="container-fluid s1">
              <div className="row">
                <div className="bg-img"></div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <h1>
                        <p className="p-1">Los sueños de</p>
                        <p className="p-2">FRIDA KAHLO</p>
                      </h1>
                    </div>
                    <div className="col-lg-6 s1-col1">
                      <a
                        className="ficha-btn-gallery"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.gtmSendEvent(e, {
                            event: "click",
                            action_name: "frida-ficha-gallery-btn",
                          });
                          this.props["set-is-comming-from-ficha"](true);
                          this.props["show-loader"](2, 0, "block", () => {
                            this.props.navigation(
                              "/frida#" +
                                this.props[
                                  "get-is-comming-from-ficha-section"
                                ]()
                            );
                          });
                        }}
                      >
                        <span>
                          <img width="102" src={galleryIco}></img>
                        </span>
                        <span>
                          Volver a <br />
                          la galería virtual
                        </span>
                      </a>
                    </div>
                    <div className="col-lg-6 s1-col2 align-self-start">
                      <a
                        href="#"
                        className="ficha-btn-tienda"
                        onClick={(e) => {
                          e.preventDefault();
                          this.gtmSendEvent(e, {
                            event: "start_form",
                            form_id: null,
                            form_name: "frida-ficha-help-btn",
                          });
                          this.props["open-form"]();
                        }}
                      >
                        Consigue tu obra
                      </a>
                      <div>
                        <p>
                          Este objeto artístico nos muestra la arrolladora
                          personalidad e indiscutible talento de esta pintora
                          orgullosamente mexicana e icono universal, además de
                          permitirnos disfrutar de todos los dibujos que existen
                          de Frida Kahlo en una obra única e irrepetible.
                        </p>
                        <p>Algo inédito y nunca antes accesible al público.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 2 */}
            <div className="container s2">
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-lg-6 s2-col2">
                  <img src="../images/frida-ficha/Web_Artika_GALERIA_Frida_Bodegon.jpg"></img>
                </div>
                <div className="col-lg-6 s2-col1">
                  <h3>
                    La obra se compone de varias piezas realizadas en distintas
                    técnicas:
                  </h3>
                  <p>
                    1. <span className="text-green">El Libro de Arte</span> es
                    una pieza de colección súper exclusiva que por primera vez
                    reúne los dibujos de la pintora, reproducidos a escala
                    original y acompañados de citas que la propia Frida dejó
                    anotadas en su diario.
                  </p>
                </div>
              </div>
            </div>
            {/* Section 3 */}
            <div className="container s3">
              <div className="row">
                <div className="col-lg-6 s3-col1">
                  <p>
                    2. <span className="text-green">La Carpeta de Arte</span>{" "}
                    permite descubrir la reproducción de una gran lámina en
                    sepia de{" "}
                    <span className="text-green">
                      Frida Kahlo, <i>El pájaro nalgón (1946)</i>,
                    </span>{" "}
                    un espectacular y enigmático mosaico de la época de su
                    aventura con el pintor{" "}
                    <span className="text-green">Josep Bartolí.</span>
                  </p>
                  <a
                    href="#"
                    className="ficha-section-btn desktop"
                    onClick={(e) => {
                      e.preventDefault();
                      this.modalVideo.current.openModalVideoBox(
                        "../videos/Artika_Galeria_Frida_Video_Tematica_2.mp4"
                      );
                      this.gtmSendEvent(e, {
                        event: "video",
                        video_title: "Artika Galeria Frida Video Tematica 2",
                      });
                    }}
                  >
                    Descubre todo
                    <br /> lo que contiene esta obra
                  </a>
                </div>
                <div className="col-lg-6 s3-col2">
                  <img src="../images/frida-ficha/Web_Artika_PROCESO_Frida_suenos.png"></img>
                </div>
                <div className="col-lg-6 s3-col3 align-self-start">
                  <a
                    href="#"
                    className="ficha-section-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      this.modalVideo.current.openModalVideoBox(
                        "../videos/Artika_Galeria_Frida_Video_Tematica_2.mp4"
                      );
                      this.gtmSendEvent(e, {
                        event: "video",
                        video_title: "Artika Galeria Frida Video Tematica 2",
                      });
                    }}
                  >
                    Descubre todo
                    <br /> lo que contiene esta obra
                  </a>
                </div>
              </div>
            </div>
            {/* Section 4 */}
            <div className="container s4">
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-lg-6 s4-col2">
                  <img src="../images/frida-ficha/Layer-14.jpg"></img>
                  {/* <a
                    href="#"
                    className="ficha-section-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props["show-loader"](2, 0, () => {
                        this.props.navigation("/frida#7");
                      });
                      this.gtmSendEvent(
                        e,
                        "click",
                        "frida-ficha-gallery-btn#7"
                      );
                    }}
                  >
                    Descubre estas obras en la galería
                  </a> */}
                </div>
                <div className="col-lg-6 s4-col1">
                  <p>
                    3. <span className="text-green">El Libro de Estudios,</span>{" "}
                    ilustrado con cerca de 100 dibujos, ofrece el primer
                    análisis de la obra de{" "}
                    <span className="text-green">Frida Kahlo</span> de la mano
                    de los expertos más reconocidos en la artista.{" "}
                    <span className="text-green">Helga Prignitz-Poda,</span>{" "}
                    coautora del catálogo razonado sobre las obras de Frida,
                    historiadora del Arte y comisaria de exposiciones, analiza
                    en profundidad el trabajo de la pintora mexicana. Completan
                    este estudio{" "}
                    <span className="text-green">
                      Juan Rafael Coronel Rivera,
                    </span>{" "}
                    periodista y poeta, nieto de Diego Rivera y María del{" "}
                    <span className="text-green">
                      Sol Argüelles San Millán,
                    </span>{" "}
                    directora del{" "}
                    <span className="text-green">
                      Museo Casa Estudio Diego Rivera y Frida Kahlo,
                    </span>{" "}
                    quienes muestran la parte más íntima de la artista.
                  </p>
                  {/* <a
                    href="#"
                    className="ficha-section-btn desktop"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props["show-loader"](2, 0, () => {
                        this.props.navigation("/frida#7");
                      });
                      this.gtmSendEvent(
                        e,
                        "click",
                        "frida-ficha-gallery-btn#7"
                      );
                    }}
                  >
                    Descubre estas obras en la galería
                  </a> */}
                </div>
              </div>
            </div>
            {/* Section 5 */}
            <div className="container s5">
              <div className="row">
                <div className="col-lg-6 s5-col1">
                  <p>
                    4. <span className="text-green">El Estuche Escultura</span>{" "}
                    exhibe el{" "}
                    <span className="text-green">
                      <i>Autorretrato con collar de espinas y colibrí (1940)</i>
                      ,
                    </span>{" "}
                    que se descubre tras un velo de hojas en madera troquelada.
                    La intensa mirada de{" "}
                    <span className="text-green">Frida</span> invita a
                    adentrarse en sus obras más personales para conocer su
                    faceta más desconocida. Su rostro oculto se desvela al igual
                    que su mundo más íntimo, descubriendo el interior de la
                    obra.
                  </p>
                  <a
                    href="#"
                    className="ficha-section-btn desktop"
                    onClick={(e) => {
                      e.preventDefault();
                      this.modalVideo.current.openModalVideoBox(
                        "../videos/Artika_Galeria_Frida_Video_Estuche_5.mp4"
                      );
                      this.gtmSendEvent(e, {
                        event: "video",
                        video_title: "Artika Galeria Frida Video Estuche 5",
                      });
                    }}
                  >
                    Conoce cómo se hizo
                    <br /> este estuche único
                  </a>
                </div>
                <div className="col-lg-6 s5-col2">
                  <img src="../images/frida-ficha/Layer-1-copy.png"></img>
                </div>
                <div className="col-lg-6 s3-col3 align-self-start">
                  <a
                    href="#"
                    className="ficha-section-btn mobile"
                    onClick={(e) => {
                      e.preventDefault();
                      this.modalVideo.current.openModalVideoBox(
                        "../videos/Artika_Galeria_Frida_Video_Estuche_5.mp4"
                      );
                      this.gtmSendEvent(e, {
                        event: "video",
                        video_title: "Artika Galeria Frida Video Estuche 5",
                      });
                    }}
                  >
                    Conoce cómo se hizo
                    <br /> este estuche único
                  </a>
                </div>
              </div>
            </div>
            {/* Section 6 */}
            <div className="container s6">
              <div className="row justify-content-md-center">
                <div className="col-lg-8 s6-col1">
                  <h3>
                    No pierdas esta oportunidad excepcional para conocer, con
                    todo detalle, la sugerente fragilidad de sus sueños, la
                    extraordinaria fuerza de su arte y su apasionante vida y
                    realidad.
                  </h3>
                  <a
                    href="#"
                    className="ficha-contactar-btn-tienda"
                    onClick={(e) => {
                      e.preventDefault();
                      this.gtmSendEvent(e, {
                        event: "start_form",
                        form_id: null,
                        form_name: "frida-ficha-contact-btn",
                      });
                      this.props["open-form"]();
                    }}
                  >
                    Contactar
                  </a>
                  <h4>
                    Comentarios de personas{" "}
                    <span className="text-green">
                      que han compra do esta obra:
                    </span>
                  </h4>
                  <p className="quote">
                    "Es una obra excelente. La tengo ubicada en el salón de la
                    casa, en un mueble especial que le hicimos. La lámina la
                    colgué en mi despacho. Todo el mundo me pregunta por ella."
                  </p>
                  <p className="quote">
                    "Desde el primer día cumplió con mis expectativas. La vi en
                    internet y enseguida pensé que tenía que tener una en casa.
                    Es una obra artística y a la vez muy decorativa. El proceso
                    de compra fue muy fácil, seguro y rápido."
                  </p>
                  <p className="quote">
                    "Frida Kahlo es la artista favorita de mi esposa, por su
                    arte y por lo que representa. Se lo regalamos en familia
                    cuando cumplió 50 años. En cuanto lo abrió quedó
                    entusiasmada. Es realmente una obra de arte."
                  </p>
                </div>
              </div>
            </div>
            {/* Section 7 */}
            <div className="container s7">
              <div className="row flex-column-reverse flex-lg-row">
                <div className="col-lg-6 s7-col2">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.modalVideo.current.openModalVideoBox(
                        "../videos/Artika_03b_ESP_MIX_211222_LOGO_v3.mp4"
                      );
                      this.gtmSendEvent(e, {
                        event: "video",
                        video_title: "Artika 03b ESP MIX 211222 LOGO v3",
                      });
                    }}
                  >
                    <img src="../images/frida-ficha/Layer-17.jpg"></img>
                  </a>
                  {/* <video
                    ref={this.artikaVideo}
                    controls
                    poster="../videos/Artika_03b_ESP_MIX_211222_LOGO_v3.jpg"
                  >
                    <source
                      src="../videos/Artika_03b_ESP_MIX_211222_LOGO_v3.mp4"
                      type="video/mp4"
                    />
                  </video> */}
                </div>
                <div className="col-lg-6 s7-col1">
                  <div className="s7-col1-content">
                    <img src="../images/frida-ficha/logo_artika_s7.png"></img>
                    <h5>Una colección de objetos artísticos</h5>
                    <h5>
                      <span className="text-green">
                        que son mucho más que libros.
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          ref={this.modalVideo}
          close-completion={this.onCloseModalVideoCompletion}
        ></ModalVideo>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <FichaFrida {...props} navigation={navigation} />;
}
