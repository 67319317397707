import React from "react";
import gsap from "gsap";

class Tycos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "pageview",
      path: "/politica-de-privacidad",
    });
    setTimeout(() => {
      this.props["hide-loader"](0, 0);
    }, 0);
  }

  componentDidUpdate() {}

  gtmSendEvent = (e, event, action) => {
    window.dataLayer.push({
      event: event,
      action_name: action,
    });
  };

  render() {
    return <div>Tycos</div>;
  }
}

export default Tycos;
