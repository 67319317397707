import React from "react";
import gsap from "gsap";
import { isMobile } from "detect-touch-device";
import Styles from "../css/modal-botero.css";

import artika from "../images/artika.png";
import menu from "../images/menu.png";
import close from "../images/close.png";
import galleryIco from "../images/gallery-ico.svg";
import mouseIco from "../images/mouse_1.svg";
import pointFloor from "../images/puntos-piso.svg";
import videoPlay from "../images/video-play.svg";
import nextTut from "../images/next-tut.svg";
import prevTut from "../images/prev-tut.svg";
import handCursor from "../images/hand-cursor.svg";
import rotationScreen from "../images/rotation-screen.png";

class ModalBotero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: "none",
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  closeModal = (event, animate = true) => {
    if (event != null) {
      event.preventDefault();
    }
    this.setState({
      displayModal: "none",
    });
    this.gtmSendEvent(null, {
      event: "click",
      action_name: "modal-botero-door-close-btn",
    });
  };

  openModal = () => {
    this.setState({
      displayModal: "flex",
    });
    this.gtmSendEvent(null, {
      event: "click",
      action_name: "modal-botero-door-open-btn",
    });
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    return (
      <>
        <div
          className="container-modal-botero"
          style={{ display: this.state.displayModal }}
        >
          <div className="box-modal-botero">
            <h2>Sala en construcción</h2>

            <p>
              Muy pronto inauguramos nuestra sala virtual dedicada a la obra{" "}
              <span>Vía Crucis de Fernando Botero,</span> un objeto artístico
              único en cuya creación el artista participó activamente.
            </p>

            <a href="#" onClick={this.closeModal} className="modal-close-btn">
              <img src={close}></img>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default ModalBotero;
