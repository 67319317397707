import React from "react";
import Cookies from "js-cookie";
import * as THREE from "three";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
import GLTFLoader from "three-gltf-loader";
import DRACOLoader from "three-dracoloader";
import FBXLoader from "three-fbxloader-offical";
import OrbitControls from "three-orbitcontrols";
import gsap from "gsap";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
import { Raycaster as a, Vector2 as h, Vector3 } from "three";
// import Stats from "three/examples/jsm/libs/stats.module";
import { isMobile } from "detect-touch-device";
import TutorialModal from "../components/TutorialModal.js";
import ModalVideo from "../components/ModalVideo.js";

import menu from "../images/menu.png";
// import sky from "../images/sky.jpg";
import next from "../images/next.png";
import prev from "../images/prev.png";
import mouse from "../images/mouse.svg";
import hand from "../images/hand.svg";
import artika from "../images/artika.png";
import on from "../images/on.svg";
import off from "../images/off.svg";
import door from "../images/puerta-sala-frida.jpg";
import person1 from "../images/person1.png";
import person2 from "../images/person2.png";
import person3 from "../images/person3.png";
import libro from "../images/ar-button.svg";
import usdz from "../images/Frida.usdz";

class Frida extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      raycastCursorPointer: "default",
      audioBtnURL: off,
      displayAudioToggle: "block",
      lookFichaBtn: "none",
      bookButtonText: "Descubre el contenido de esta obra al detalle.",
    };
    this.w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.manager = new THREE.LoadingManager();
    this.museum = null;
    this.book = null;
    this.tutorialModal = React.createRef();
    this.currentSection = 1;
    this.previousSection = 1;
    this.isAnimating = false;
    this.pointer = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.prevButton = React.createRef();
    this.nextButton = React.createRef();
    this.chatButton = React.createRef();
    this.mouseDown = false;
    this.touchStart = false;
    this.lastControlsPosition = null;
    this.toogleAudio = React.createRef();
    this.audioWasPlayingWhenOpenModalVideo = false;
    this.espectadorWomanMesh02 = null;
    this.espectadorWomanMesh03 = null;
    this.tutorialIsHidden = false;
    this.formIsHidden = true;
    // this.playback = this.props["playback"];
    this.hash = document.location.hash;
    this.meshTutBook = null;
    this.meshTutBook2 = null;
    this.modalVideo = React.createRef();
    // this.usdzButton = React.createRef();
    // this.lookFichaBtn = React.createRef();
    this.showLookFichaBtn = false;
    this.ua = window.navigator.userAgent;
    this.iOS = this.ua.match(/iPad/i) || this.ua.match(/iPhone/i);
    this.webkit = this.ua.match(/WebKit/i);
    this.iOSSafari = this.iOS && this.webkit && !this.ua.match(/CriOS/i);
  }

  componentDidMount() {
    console.log("component did mount");
    window.dataLayer.push({
      event: "pageview",
      pageName: "frida",
      pageType: "galeria virtual",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });

    this.props["set-loader-text-display"]("block");

    document.addEventListener("mousemove", this.onPointerMove);
    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("touchstart", this.onTouchStart);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("touchend", this.onTouchEnd);
    window.addEventListener("resize", this.onWindowResize, true);
    window.addEventListener("orientationchange", this.onWindowRotate, false);
    this.onWindowResize();

    // this.stats = Stats();
    // document.body.appendChild(this.stats.dom);

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.scene = new THREE.Scene();

    // Add Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    this.mount.appendChild(this.renderer.domElement);

    // Add Camera
    this.camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
    this.camera.position.z = 22;
    this.camera.position.y = 0;
    this.camera.position.x = 200 + 5;
    // this.camera.rotation.y = this.degToRad(90);
    // this.camera.lookAt(100, 0, 0);

    // Camera Controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.rotateSpeed *= isMobile ? -0.2 : -0.1;
    this.controls.target.set(199.9, 0, 22);
    // this.controls.target.set(214.9, 0, 22);
    this.controls.enableDamping = true;
    // this.controls.dampingFactor = 0.01;
    this.controls.enableZoom = false;
    this.controls.enableRotate = true;
    this.controls.enablePan = false;
    this.controls.maxPolarAngle = Math.PI / 1.6;
    this.controls.minPolarAngle = Math.PI / 2.2;
    this.controls.maxDistance = 5;
    this.controls.minDistance = 5;
    this.controls.update();

    // Add Lights
    this.ambientLight = new THREE.AmbientLightProbe(0xffffff, 1);
    this.scene.add(this.ambientLight);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.4);
    hemiLight.position.set(0, 28, 0);
    this.scene.add(hemiLight);

    // Start Scene
    this.renderScene();
    this.start();
    this.startLoaderManager();
  }

  onWindowRotate = () => {
    console.log("window rotate");
    this.updateCameraFov();
    this.onWindowResize();
    this.renderScene();
  };

  updateCameraFov = () => {
    if (isMobile) {
      if (this.isLandscapeMode()) {
        this.camera.fov = 35;
      } else {
        this.camera.fov = 45;
      }
    } else {
      this.camera.fov = 45;
    }
  };

  componentDidUpdate() {
    console.log("component did update");
  }

  componentWillUnmount() {
    this.stop();
    document.removeEventListener("mousemove", this.onPointerMove);
    document.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("touchstart", this.onTouchStart);
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("resize", this.onWindowResize, true);
    window.removeEventListener("orientationchange", this.onWindowRotate, false);
    this.dispose();
  }

  dispose = (e) => {
    this.scene.traverse((o) => {
      if (o.geometry) {
        o.geometry.dispose();
        console.log("dispose geometry ", o.geometry);
      }

      if (o.material) {
        if (o.material.length) {
          for (let i = 0; i < o.material.length; ++i) {
            o.material[i].dispose();
            console.log("dispose material ", o.material[i]);
          }
        } else {
          o.material.dispose();
          console.log("dispose material ", o.material);
        }
      }
    });

    this.renderer.dispose();
    this.museum = null;
    this.book = null;
    this.controls = null;
    this.camera = null;
    this.renderer = null;
    this.mount = null;
  };

  startLoaderManager = () => {
    this.manager.onStart = function (url, itemsLoaded, itemsTotal) {
      console.log();
    };

    this.manager.onLoad = () => {
      this.onWindowRotate();
      if (this.isHashValid() == true) {
        this.tutorialModal.current.closeModal(null, false);
        let section = parseInt(this.hash.replace("#", ""));
        this.props["set-current-section"](section);
        this.nextSectionFn(section, 0, true);
      } else {
        if (Cookies.get("tutorial") == 1) {
          this.tutorialIsHidden = true;
          this.tutorialModal.current.closeModal(null, false);
        }
      }
      this.props["hide-loader"](2, 2);
    };

    this.manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      console.log();
    };

    this.manager.onError = function (url) {};

    // this.loadSkyBox();
    this.loadMuseumModel();
    this.loadEspectadores();
    this.loadDoor();
    this.loadBookModel();
    this.loadVideoPlanes();
    this.loadTextPlanes();
    this.loadNavigationPlanes();
  };

  startSceneControls = () => {
    this.isAnimating = true;
    this.tutorialIsHidden = true;
    this.startCameraControls();
    this.props["set-is-comming-from-ficha"](false);
    this.props["set-is-comming-from-ficha-section"](0);

    if (Cookies.get("tutorial") == 0 || Cookies.get("tutorial") == undefined) {
      Cookies.set("tutorial", 1);
    }

    this.meshTutBook.material.opacity = 0;
    this.meshTutBook.visible = true;

    gsap.to(this.meshTutBook.material, {
      duration: 0.5,
      delay: 1,
      opacity: 1,
      onComplete: () => {},
    });
  };

  startCameraControls = () => {
    this.isAnimating = false;
  };

  onPointerMove = (event) => {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  onMouseDown = (event) => {
    if (this.controls == null) {
      return;
    }
    this.mouseDown = true;

    this.controls.saveState();
    this.lastControlsPosition = new Vector3(
      this.controls.position0.x,
      this.controls.position0.y,
      this.controls.position0.z
    );
  };

  onTouchStart = (event) => {
    if (this.controls == null) {
      return;
    }
    this.touchStart = true;

    this.controls.saveState();
    this.lastControlsPosition = new Vector3(
      this.controls.position0.x,
      this.controls.position0.y,
      this.controls.position0.z
    );
  };

  onMouseUp = (event) => {
    this.mouseDown = false;
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    if (isMobile) {
      // if (event.clientX > 80) {
      //this.checkRaycastingMouseDown();
      // } else if (window.innerHeight - event.clientY > 160) {
      //this.checkRaycastingMouseDown();
      // }
    } else if (!isMobile) {
      if (event.clientX > 189) {
        this.checkRaycastingMouseDown();
      } else if (window.innerHeight - event.clientY > 165) {
        this.checkRaycastingMouseDown();
      }
    }
  };

  onTouchEnd = (event) => {
    this.touchStart = false;
    this.pointer.x =
      (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
    this.pointer.y =
      -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;

    if (isMobile) {
      if (event.changedTouches[0].clientX > 150) {
        this.checkRaycastingMouseDown();
      } else if (window.innerHeight - event.changedTouches[0].clientY > 127) {
        this.checkRaycastingMouseDown();
      }
    } else if (!isMobile) {
      // this.checkRaycastingMouseDown();
    }
  };

  // playbackPause = () => {
  //   if (!this.playback.current.paused) {
  //     this.audioWasPlayingWhenOpenModalVideo = true;
  //   }
  //   this.playback.current.pause();
  //   this.setState({
  //     audioBtnURL: off,
  //   });
  // };

  onCloseModalVideoCompletion = () => {
    // if (this.audioWasPlayingWhenOpenModalVideo == true) {
    //   this.playback.current.volume = 1;
    //   this.playback.current.currentTime = 0;
    //   this.playback.current.play();
    //   this.setState({
    //     audioBtnURL: on,
    //   });
    // }
    // this.audioWasPlayingWhenOpenModalVideo = false;
  };

  checkRaycastingMouseDown = () => {
    if (this.props["menu"].current.isMenuOpened) {
      return;
    }
    if (this.tutorialIsHidden == false) {
      return;
    }
    if (this.props["form"].current.isFormOpened) {
      return;
    }
    if (this.controls == null) {
      return;
    } else {
      this.controls.saveState();
      if (
        this.controls.position0.distanceTo(this.lastControlsPosition) > 0.05
      ) {
        return;
      }
    }
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    );
    console.log(intersects);
    if (intersects.length > 0) {
      var first = intersects[0].object;

      if (first.name == "Suaje_Madera1" || first.name == "pCube1") {
        // if (isMobile) {
        //   return;
        // }
        this.gtmSendEvent(null, {
          event: "view_content",
          content_name: "gallery-frida-book1-btn",
        });
        this.controls.enableRotate = false;
        this.tutorialIsHidden = false;
        // this.props["set-is-comming-from-ficha-section"](1);
        // this.props["show-loader"](2, 0, "none", () => {
        //   this.props.navigation("/frida/ficha");
        // });
        window.location.href = "/fridaficha/product.php?section=1";
      } else if (first.name == "Suaje_Madera2" || first.name == "pCube2") {
        // if (isMobile) {
        //   return;
        // }
        this.gtmSendEvent(null, {
          event: "view_content",
          content_name: "gallery-frida-book2-btn",
        });
        this.controls.enableRotate = false;
        this.tutorialIsHidden = false;
        // this.props["set-is-comming-from-ficha-section"](2);
        // this.props["show-loader"](2, 0, "none", () => {
        //   this.props.navigation("/frida/ficha");
        // });
        window.location.href = "/fridaficha/product.php?section=8";
      } else if (first.name == "video01") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "../videos/Artika_Galeria_Frida_Video_Intro_1.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika Galeria Frida Video Intro 1",
        });
      } else if (first.name == "video02") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "../videos/Artika_Galeria_Frida_Video_Tematica_2.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika Galeria Frida Video Tematica 2",
        });
      } else if (first.name == "video03") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "../videos/Artika_Galeria_Frida_Video_Introduccion_al_artista_3.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika Galeria Frida Video Introduccion al artista 3",
        });
      } else if (first.name == "video04") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "../videos/Artika_Galeria_Frida_Video_Como_fue_hecha_la_obra_4.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika Galeria Frida Video Como fue hecha la obra 4",
        });
      } else if (first.name == "video05") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "../videos/Artika_Galeria_Frida_Video_Estuche_5.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika Galeria Frida Video Estuche 5",
        });
      } else if (first.name == "video06") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "../videos/Artika_Galeria_Frida_Video_Prensa_6.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika Galeria Frida Video Prensa 6",
        });
      } else if (first.name == "nav01") {
        this.nextSectionFn(1);
      } else if (first.name == "nav02") {
        this.nextSectionFn(2);
      } else if (first.name == "nav03") {
        this.nextSectionFn(3);
      }
      // else if (first.name == "nav04") {
      //   this.nextSectionFn(4);
      // }
      else if (first.name == "nav05") {
        this.nextSectionFn(4);
      } else if (first.name == "nav06") {
        this.nextSectionFn(5);
      } else if (first.name == "nav07") {
        this.nextSectionFn(6);
      } else if (first.name == "nav08") {
        this.nextSectionFn(7);
      } else if (first.name == "nav09") {
        this.nextSectionFn(8);
      } else if (first.name == "nav10") {
        this.nextSectionFn(9);
      } else if (first.name == "door") {
        this.gtmSendEvent(null, {
          event: "click",
          action_name: "gallery-frida-door-btn",
        });
        this.controls.enableRotate = false;
        this.tutorialIsHidden = false;
        this.props["show-loader"](2, 0, "block", null, () => {
          this.props.navigation("/");
        });
      } else if (first.name == "pop-up") {
        this.props["open-form"]();
        this.gtmSendEvent(null, {
          event: "click",
          action_name: "gallery-popup-btn",
        });
      } else if (
        first.name == "book-tut-text" &&
        (this.currentSection == 0 || this.currentSection == 1) &&
        this.isAnimating == false
      ) {
        this.scene.remove(this.meshTutBook);
        this.meshTutBook = null;
      } else if (
        first.name == "book-tut-text2" &&
        this.currentSection == 8 &&
        this.isAnimating == false
      ) {
        this.scene.remove(this.meshTutBook2);
        this.meshTutBook2 = null;
      }
    }
  };

  loadMuseumModel = () => {
    const loader = new FBXLoader(this.manager);
    loader.load("../fbx/Sala_Frida_V07.fbx", (object) => {
      this.museum = object;
      this.museum.scale.set(18, 18, 18);
      this.museum.position.set(0, -10, 0);
      this.scene.add(this.museum);
      this.loadMuseumTextures();
    });
  };

  loadMuseumTextures = () => {
    this.museum.traverse((child) => {
      if (child.isMesh) {
        console.log(child.name);

        // Cuadros

        if (child.name == "MOD_02_Artesania6") {
          var cuadro05 = new THREE.TextureLoader(this.manager).load(
            "../fbx/048-artesania.jpg",
            () => {
              child.material.map = cuadro05;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_02_Artesania5") {
          var cuadro04 = new THREE.TextureLoader(this.manager).load(
            "../fbx/020-artesania.jpg",
            () => {
              child.material.map = cuadro04;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_05_Raices1") {
          var cuadro01 = new THREE.TextureLoader(this.manager).load(
            "../fbx/raices.jpg",
            () => {
              child.material.map = cuadro01;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        // Modulo 1 Frida

        if (child.name == "MOD_01_Frida1") {
          var modulo01 = new THREE.TextureLoader(this.manager).load(
            "../fbx/modulo01.jpg",
            () => {
              child.material.map = modulo01;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_01_Back1") {
          var modulo01Back = new THREE.TextureLoader(this.manager).load(
            "../fbx/modulo01Back.jpg",
            () => {
              child.material.map = modulo01Back;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "Area_04_Libro1") {
          var area04Libro = new THREE.TextureLoader(this.manager).load(
            "../fbx/32-Libro-arte1.png",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: area04Libro,
                transparent: true,
              });
              child.material = material;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MURo_07_las2Fridas1") {
          var muro07 = new THREE.TextureLoader(this.manager).load(
            "../fbx/muro07.jpg",
            () => {
              child.material.map = muro07;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "Museo_Muro") {
          var museoMuros = new THREE.TextureLoader(this.manager).load(
            "../fbx/Museo_Muros_V3_.jpg",
            () => {
              child.material.map = museoMuros;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "Museo_castillos") {
          var museoCastillos = new THREE.TextureLoader(this.manager).load(
            "../fbx/Museo_Castillos_V41.jpg",
            () => {
              child.material.map = museoCastillos;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "Museo_Bancas1") {
          var museoBancas = new THREE.TextureLoader(this.manager).load(
            "../fbx/museoBancasV2.jpg",
            () => {
              child.material.map = museoBancas;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "Museo_piso") {
          var museoPiso = new THREE.TextureLoader(this.manager).load(
            "../fbx/museoPisosV31_.jpg",
            () => {
              child.material.map = museoPiso;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "Museo_Techado") {
          var museoTechado = new THREE.TextureLoader(this.manager).load(
            "../fbx/museoTechadoV2.jpg",
            () => {
              child.material.map = museoTechado;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_05_SinEsperenza1") {
          var modulo05 = new THREE.TextureLoader(this.manager).load(
            "../fbx/modulo05.jpg",
            () => {
              child.material.map = modulo05;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_01_Aresania1") {
          var cuadro02 = new THREE.TextureLoader(this.manager).load(
            "../fbx/136-artesania.jpg",
            () => {
              child.material.map = cuadro02;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_01_Artesanias3") {
          var cuadro03 = new THREE.TextureLoader(this.manager).load(
            "../fbx/139-artesania.jpg",
            () => {
              child.material.map = cuadro03;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_06_084") {
          var cuadro06 = new THREE.TextureLoader(this.manager).load(
            "../fbx/082-artesania.jpg",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: cuadro06,
                transparent: true,
              });
              child.material = material;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_06_083") {
          var cuadro07 = new THREE.TextureLoader(this.manager).load(
            "../fbx/063-artesania.jpg",
            () => {
              child.material.map = cuadro07;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        if (child.name == "MOD_02_FridaEspinas1") {
          var cuadro08 = new THREE.TextureLoader(this.manager).load(
            "../fbx/Autorretrato_con_espinas2.png",
            () => {
              var cuadro08Material = new THREE.MeshBasicMaterial({
                map: cuadro08,
                transparent: true,
                opacity: 0.9,
              });
              // cuadro08Material.blending = THREE.CustomBlending;
              // cuadro08Material.blendSrc = THREE.SrcAlphaFactor;
              // cuadro08Material.blendDst = THREE.OneMinusSrcAlphaFactor;
              // cuadro08Material.blendEquation = THREE.AddEquation;
              child.material = cuadro08Material;
              // child.material.map.minFilter = THREE.LinearFilter;
              child.visible = true;
            }
          );
        }

        // Marcos

        if (
          child.name == "Marco" ||
          child.name == "Marco2" ||
          child.name == "Marco3" ||
          child.name == "Marco4" ||
          child.name == "Marco5" ||
          child.name == "Marco6"
        ) {
          var marco = new THREE.TextureLoader(this.manager).load(
            "../fbx/marco.jpg",
            () => {
              child.material.map = marco;
              child.visible = true;
            }
          );
        }
      }
    });
  };

  loadEspectadores = () => {
    /////////////////////
    var espectadorManTexture = new THREE.TextureLoader(this.manager).load(
      "../fbx/hombre_221020.png",
      () => {}
    );

    var espactadorManMaterial = new THREE.MeshBasicMaterial({
      map: espectadorManTexture,
      transparent: true,
      // alphaTest: 0.5,
    });

    var espectadorManGeometry = new THREE.PlaneBufferGeometry(4.5, 16);

    /////////////////////
    var espectadorWomenTexture = new THREE.TextureLoader(this.manager).load(
      "../fbx/mujer_221020.png",
      () => {}
    );

    var espectadorWomanMaterial = new THREE.MeshBasicMaterial({
      map: espectadorWomenTexture,
      transparent: true,
      // alphaTest: 0.5,
      opacity: 0.8,
    });

    var espectadorWomanGeometry = new THREE.PlaneBufferGeometry(4, 15);

    //
    this.espectadorWomanMesh02 = new THREE.Mesh(
      espectadorWomanGeometry,
      espectadorWomanMaterial
    );
    this.espectadorWomanMesh02.name = "espectadorWomen02";
    this.espectadorWomanMesh02.position.set(-23, -2.2, 41);
    this.espectadorWomanMesh02.rotation.y = this.degToRad(95);
    this.scene.add(this.espectadorWomanMesh02);

    //
    this.espectadorWomanMesh03 = new THREE.Mesh(
      espectadorWomanGeometry,
      espectadorWomanMaterial
    );
    this.espectadorWomanMesh03.name = "espectadorWomen02";
    this.espectadorWomanMesh03.position.set(-197, -2.2, -59);
    this.espectadorWomanMesh03.rotation.y = this.degToRad(90);
    this.scene.add(this.espectadorWomanMesh03);
  };

  loadBookModel = () => {
    this.loader = new FBXLoader(this.manager);
    this.loader.load("../fbx/Libro_Frida_V2.fbx", (object) => {
      this.book = object;
      this.book.scale.set(0.23, 0.23, 0.23);
      this.book.position.set(-183, -0.9, -36);
      this.book.rotation.x = this.degToRad(-90);
      this.book.rotation.z = this.degToRad(-90);
      this.book.rotation.z = this.degToRad(90);
      this.book.rotation.y = this.degToRad(90);
      this.scene.add(this.book);

      //

      var texture = new THREE.TextureLoader(this.manager).load(
        "../images/textos/text-tut-book.png",
        (texture) => {}
      );

      var material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
      });

      var geometry = new THREE.PlaneBufferGeometry(60, 26);
      this.meshTutBook = new THREE.Mesh(geometry, material);
      this.meshTutBook.name = "book-tut-text";
      this.meshTutBook.position.set(193, 0.8, 22);
      this.meshTutBook.scale.set(0.055, 0.055, 0.055);
      this.meshTutBook.rotation.y = this.degToRad(90);
      this.meshTutBook.visible = false;
      this.scene.add(this.meshTutBook);

      // book glow
      var textureGlowBook = new THREE.TextureLoader(this.manager).load(
        "../fbx/circleBook.png",
        (texture) => {}
      );

      var materialBookGlow = new THREE.MeshBasicMaterial({
        transparent: true,
        map: textureGlowBook,
      });

      var bookGlowGeom = new THREE.PlaneBufferGeometry(8, 8);
      this.bookMesh = new THREE.Mesh(bookGlowGeom, materialBookGlow);
      this.bookMesh.scale.set(0.2, 0.2, 0.2);
      this.bookMesh.position.set(-186, -0.6, -36);
      this.bookMesh.rotation.y = this.degToRad(90);
      this.scene.add(this.bookMesh);

      this.animGlowBook();
    });

    //

    var texture2 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/text-tut-book1.png",
      (texture) => {}
    );

    var material2 = new THREE.MeshBasicMaterial({
      map: texture2,
      transparent: true,
    });

    var geometry2 = new THREE.PlaneBufferGeometry(60, 26);
    this.meshTutBook2 = new THREE.Mesh(geometry2, material2);
    this.meshTutBook2.name = "book-tut-text2";
    this.meshTutBook2.position.set(-183, 1.3, -36);
    this.meshTutBook2.scale.set(0.055, 0.055, 0.055);
    this.meshTutBook2.rotation.y = this.degToRad(90);
    this.meshTutBook2.visible = false;
    this.scene.add(this.meshTutBook2);
  };

  animGlowBook = () => {
    this.bookMesh.scale.set(0.2, 0.2, 0.2);
    var size = 1.0;
    if (this.currentSection <= 2) {
      size = 1.7;
    } else if (this.currentSection <= 6) {
      size = 1.4;
    } else {
      size = 1.1;
    }
    this.bookMeshAnim = gsap.to(this.bookMesh.scale, {
      duration: 1,
      x: size,
      y: size,
      z: size,
      yoyo: true,
      repeat: 1,
      ease: "power1.inOut",
      onComplete: () => {
        this.animGlowBook();
      },
    });
  };

  loadVideoPlanes = () => {
    //
    var video01Texture = new THREE.TextureLoader(this.manager).load(
      "../videos/video01.jpg",
      (texture) => {}
    );

    var video01Material = new THREE.MeshBasicMaterial({
      map: video01Texture,
      transparent: false,
    });
    // this.video01Material.map.minFilter = THREE.LinearFilter;

    var video01Geometry = new THREE.PlaneBufferGeometry(436, 245);
    var video01Mesh = new THREE.Mesh(video01Geometry, video01Material);
    video01Mesh.name = "video01";
    video01Mesh.position.set(155, 6, 67);
    video01Mesh.scale.set(0.07, 0.07, 0.07);
    video01Mesh.rotation.y = this.degToRad(180);
    this.scene.add(video01Mesh);

    //
    var video02Texture = new THREE.TextureLoader(this.manager).load(
      "../videos/video02.jpg",
      (texture) => {}
    );

    var video02Material = new THREE.MeshBasicMaterial({
      map: video02Texture,
      transparent: false,
    });

    var video02Geometry = new THREE.PlaneBufferGeometry(436, 245);
    var video02Mesh = new THREE.Mesh(video02Geometry, video02Material);
    video02Mesh.name = "video02";
    video02Mesh.position.set(-11, 4, 67);
    video02Mesh.scale.set(0.07, 0.07, 0.07);
    video02Mesh.rotation.y = this.degToRad(180);
    this.scene.add(video02Mesh);

    //
    var video03Texture = new THREE.TextureLoader(this.manager).load(
      "../videos/video03.jpg",
      (texture) => {}
    );

    var video03Material = new THREE.MeshBasicMaterial({
      map: video03Texture,
      transparent: false,
    });

    var video03Geometry = new THREE.PlaneBufferGeometry(436, 245);
    var video03Mesh = new THREE.Mesh(video03Geometry, video03Material);
    video03Mesh.name = "video03";
    video03Mesh.position.set(3, 3, -24);
    video03Mesh.scale.set(0.07, 0.07, 0.07);
    video03Mesh.rotation.y = this.degToRad(0);
    this.scene.add(video03Mesh);

    //
    var video04Texture = new THREE.TextureLoader(this.manager).load(
      "../videos/video04.jpg",
      (texture) => {}
    );

    var video04Material = new THREE.MeshBasicMaterial({
      map: video04Texture,
      transparent: false,
    });

    var video04Geometry = new THREE.PlaneBufferGeometry(436, 245);
    var video04Mesh = new THREE.Mesh(video04Geometry, video04Material);
    video04Mesh.name = "video04";
    video04Mesh.position.set(-41, 5, -40);
    video04Mesh.scale.set(0.07, 0.07, 0.07);
    video04Mesh.rotation.y = this.degToRad(-44);
    this.scene.add(video04Mesh);

    //
    var video05Texture = new THREE.TextureLoader(this.manager).load(
      "../videos/video05.jpg",
      (texture) => {}
    );

    var video05Material = new THREE.MeshBasicMaterial({
      map: video05Texture,
      transparent: false,
    });

    var video05Geometry = new THREE.PlaneBufferGeometry(436, 245);
    var video05Mesh = new THREE.Mesh(video05Geometry, video05Material);
    video05Mesh.name = "video05";
    video05Mesh.position.set(-148, 6, -79);
    video05Mesh.scale.set(0.07, 0.07, 0.07);
    video05Mesh.rotation.y = this.degToRad(0);
    this.scene.add(video05Mesh);

    //
    var video06Texture = new THREE.TextureLoader(this.manager).load(
      "../videos/video06.jpg",
      (texture) => {}
    );

    var video06Material = new THREE.MeshBasicMaterial({
      map: video06Texture,
      transparent: false,
    });

    var video06Geometry = new THREE.PlaneBufferGeometry(436, 245);
    var video06Mesh = new THREE.Mesh(video06Geometry, video06Material);
    video06Mesh.name = "video06";
    video06Mesh.position.set(113.5, 6, 53);
    video06Mesh.scale.set(0.05, 0.05, 0.05);
    video06Mesh.rotation.y = this.degToRad(90);
    this.scene.add(video06Mesh);
  };

  loadDoor = () => {
    var textureDoor = new THREE.TextureLoader(this.manager).load(
      door,
      (texture) => {}
    );

    var materialDoor = new THREE.MeshBasicMaterial({
      map: textureDoor,
      side: THREE.DoubleSide,
    });

    var geometryDoor = new THREE.PlaneBufferGeometry(260, 339);
    var meshDoor = new THREE.Mesh(geometryDoor, materialDoor);
    meshDoor.name = "door";
    meshDoor.position.set(268, 2, 22);
    meshDoor.scale.set(0.07, 0.07, 0.07);
    meshDoor.rotation.y = this.degToRad(90);
    this.scene.add(meshDoor);
  };

  loadTextPlanes = () => {
    //
    var textureText01_1 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto01_1.png",
      (texture) => {}
    );

    var materialText01_1 = new THREE.MeshBasicMaterial({
      map: textureText01_1,
      transparent: true,
      opacity: 0.85,
    });
    // materialText01_1.map.minFilter = THREE.LinearFilter;

    var geometryText01_1 = new THREE.PlaneBufferGeometry(267, 15);
    var meshText01_1 = new THREE.Mesh(geometryText01_1, materialText01_1);
    meshText01_1.name = "text01_1";
    meshText01_1.position.set(268, 16, 22);
    meshText01_1.scale.set(0.07, 0.07, 0.07);
    meshText01_1.rotation.y = this.degToRad(270);
    this.scene.add(meshText01_1);

    //
    var textureText01 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto01.png",
      (texture) => {}
    );

    var materialText01 = new THREE.MeshBasicMaterial({
      map: textureText01,
      transparent: true,
      opacity: 0.85,
    });
    // materialText01.map.minFilter = THREE.LinearFilter;

    var geometryText01 = new THREE.PlaneBufferGeometry(380, 339);
    var meshText01 = new THREE.Mesh(geometryText01, materialText01);
    meshText01.name = "text01";
    meshText01.position.set(223, 7, -24);
    meshText01.scale.set(0.07, 0.07, 0.07);
    meshText01.rotation.y = this.degToRad(0);
    this.scene.add(meshText01);

    //
    var textureText02 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto02.png",
      (texture) => {}
    );

    var materialText02 = new THREE.MeshBasicMaterial({
      map: textureText02,
      transparent: true,
      opacity: 0.85,
    });
    // materialText02.map.minFilter = THREE.LinearFilter;

    var geometryText02 = new THREE.PlaneBufferGeometry(290, 267);
    var meshText02 = new THREE.Mesh(geometryText02, materialText02);
    meshText02.name = "text02";
    meshText02.position.set(223, 7, 67);
    meshText02.scale.set(0.07, 0.07, 0.07);
    meshText02.rotation.y = this.degToRad(180);
    this.scene.add(meshText02);

    //
    var textureText02_1 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto02_1.png",
      (texture) => {}
    );

    var materialText02_1 = new THREE.MeshBasicMaterial({
      map: textureText02_1,
      transparent: true,
      opacity: 0.85,
    });
    // materialText02_1.map.minFilter = THREE.LinearFilter;

    var geometryText02_1 = new THREE.PlaneBufferGeometry(320, 27);
    var meshText02_1 = new THREE.Mesh(geometryText02_1, materialText02_1);
    meshText02_1.name = "text02_1";
    meshText02_1.position.set(155, 17.5, 67);
    meshText02_1.scale.set(0.07, 0.07, 0.07);
    meshText02_1.rotation.y = this.degToRad(180);
    this.scene.add(meshText02_1);

    //
    var textureText03 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto03.png",
      (texture) => {}
    );

    var materialText03 = new THREE.MeshBasicMaterial({
      map: textureText03,
      transparent: true,
      opacity: 0.85,
    });
    // materialText03.map.minFilter = THREE.LinearFilter;

    var geometryText03 = new THREE.PlaneBufferGeometry(496, 267);
    var meshText03 = new THREE.Mesh(geometryText03, materialText03);
    meshText03.name = "text03";
    meshText03.position.set(73, 7, -24);
    meshText03.scale.set(0.07, 0.07, 0.07);
    meshText03.rotation.y = this.degToRad(0);
    this.scene.add(meshText03);

    //
    var textureText04 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto04.png",
      (texture) => {}
    );

    var materialText04 = new THREE.MeshBasicMaterial({
      map: textureText04,
      transparent: true,
      opacity: 0.85,
    });
    // materialText04.map.minFilter = THREE.LinearFilter;

    var geometryText04 = new THREE.PlaneBufferGeometry(377, 55);
    var meshText04 = new THREE.Mesh(geometryText04, materialText04);
    meshText04.name = "text04";
    meshText04.position.set(-11, 17, 67);
    meshText04.scale.set(0.07, 0.07, 0.07);
    meshText04.rotation.y = this.degToRad(180);
    this.scene.add(meshText04);

    //
    var textureText05 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto05.png",
      (texture) => {}
    );

    var materialText05 = new THREE.MeshBasicMaterial({
      map: textureText05,
      transparent: true,
      opacity: 0.85,
    });
    // materialText05.map.minFilter = THREE.LinearFilter;

    var geometryText05 = new THREE.PlaneBufferGeometry(377, 93);
    var meshText05 = new THREE.Mesh(geometryText05, materialText05);
    meshText05.name = "text05";
    meshText05.position.set(3, 17, -24);
    meshText05.scale.set(0.07, 0.07, 0.07);
    meshText05.rotation.y = this.degToRad(0);
    this.scene.add(meshText05);

    //
    var textureText06 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto06.png",
      (texture) => {}
    );

    var materialText06 = new THREE.MeshBasicMaterial({
      map: textureText06,
      transparent: true,
      opacity: 0.85,
    });
    // materialText06.map.minFilter = THREE.LinearFilter;

    var geometryText06 = new THREE.PlaneBufferGeometry(377, 58);
    var meshText06 = new THREE.Mesh(geometryText06, materialText06);
    meshText06.name = "text06";
    meshText06.position.set(-41, 17, -40);
    meshText06.scale.set(0.07, 0.07, 0.07);
    meshText06.rotation.y = this.degToRad(-44);
    this.scene.add(meshText06);

    //
    var textureText07 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto07.png",
      (texture) => {}
    );

    var materialText07 = new THREE.MeshBasicMaterial({
      map: textureText07,
      transparent: true,
      opacity: 0.85,
    });
    // materialText07.map.minFilter = THREE.LinearFilter;

    var geometryText07 = new THREE.PlaneBufferGeometry(310, 103);
    var meshText07 = new THREE.Mesh(geometryText07, materialText07);
    meshText07.name = "text07";
    meshText07.position.set(-65, 15, -63);
    meshText07.scale.set(0.07, 0.07, 0.07);
    meshText07.rotation.y = this.degToRad(-44);
    this.scene.add(meshText07);

    //
    var textureText08 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto08.png",
      (texture) => {}
    );

    var materialText08 = new THREE.MeshBasicMaterial({
      map: textureText08,
      transparent: true,
      opacity: 0.85,
    });
    // materialText08.map.minFilter = THREE.LinearFilter;

    var geometryText08 = new THREE.PlaneBufferGeometry(558, 90);
    var meshText08 = new THREE.Mesh(geometryText08, materialText08);
    meshText08.name = "text08";
    meshText08.position.set(-92, 20, 40);
    meshText08.scale.set(0.07, 0.07, 0.07);
    meshText08.rotation.y = this.degToRad(136);
    this.scene.add(meshText08);

    //
    var textureText09 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto09.png",
      (texture) => {}
    );

    var materialText09 = new THREE.MeshBasicMaterial({
      map: textureText09,
      transparent: true,
      opacity: 0.85,
    });
    // materialText09.map.minFilter = THREE.LinearFilter;

    var geometryText09 = new THREE.PlaneBufferGeometry(461, 60);
    var meshText09 = new THREE.Mesh(geometryText09, materialText09);
    meshText09.name = "text09";
    meshText09.position.set(-106, 8.5, -79);
    meshText09.scale.set(0.07, 0.07, 0.07);
    meshText09.rotation.y = this.degToRad(0);
    this.scene.add(meshText09);

    //
    var textureText10 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto10.png",
      (texture) => {}
    );

    var materialText10 = new THREE.MeshBasicMaterial({
      map: textureText10,
      transparent: true,
      opacity: 0.85,
    });
    // materialText10.map.minFilter = THREE.LinearFilter;

    var geometryText10 = new THREE.PlaneBufferGeometry(258, 30);
    var meshText10 = new THREE.Mesh(geometryText10, materialText10);
    meshText10.name = "text10";
    meshText10.position.set(-147.5, 17, -79);
    meshText10.scale.set(0.07, 0.07, 0.07);
    meshText10.rotation.y = this.degToRad(0);
    this.scene.add(meshText10);

    //
    var textureText11 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto11.png",
      (texture) => {}
    );

    var materialText11 = new THREE.MeshBasicMaterial({
      map: textureText11,
      transparent: true,
      opacity: 0.85,
    });
    materialText11.map.minFilter = THREE.LinearFilter;

    var geometryText11 = new THREE.PlaneBufferGeometry(502, 397);
    var meshText11 = new THREE.Mesh(geometryText11, materialText11);
    meshText11.name = "text11";
    meshText11.position.set(-150.5, 7, 12);
    meshText11.scale.set(0.07, 0.07, 0.07);
    meshText11.rotation.y = this.degToRad(180);
    this.scene.add(meshText11);

    //
    var textureText12 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto12.png",
      (texture) => {}
    );

    var materialText12 = new THREE.MeshBasicMaterial({
      map: textureText12,
      transparent: true,
      opacity: 0.85,
    });
    // materialText12.map.minFilter = THREE.LinearFilter;

    var geometryText12 = new THREE.PlaneBufferGeometry(333, 50);
    var meshText12 = new THREE.Mesh(geometryText12, materialText12);
    meshText12.name = "text11";
    meshText12.position.set(113.5, 16.5, 53);
    meshText12.scale.set(0.065, 0.065, 0.065);
    meshText12.rotation.y = this.degToRad(90);
    this.scene.add(meshText12);

    //
    var textureText13 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto13.png",
      (texture) => {}
    );

    var materialText13 = new THREE.MeshBasicMaterial({
      map: textureText13,
      transparent: true,
    });

    var geometryText13 = new THREE.PlaneBufferGeometry(600, 158);
    var meshText13 = new THREE.Mesh(geometryText13, materialText13);
    meshText13.name = "text12";
    meshText13.position.set(-232.5, 7, -36);
    meshText13.scale.set(0.07, 0.07, 0.07);
    meshText13.rotation.y = this.degToRad(90);
    this.scene.add(meshText13);

    //
    // var textureText14 = new THREE.TextureLoader(this.manager).load(
    //   "./images/textos/texto14.png",
    //   (texture) => {}
    // );

    // var materialText14 = new THREE.MeshBasicMaterial({
    //   map: textureText14,
    //   transparent: true,
    //   opacity: 0.85,
    // });

    // var geometryText14 = new THREE.PlaneBufferGeometry(300, 140);
    // var meshText14 = new THREE.Mesh(geometryText14, materialText14);
    // meshText14.name = "text14";
    // meshText14.position.set(113.5, 9, 53);
    // meshText14.scale.set(0.07, 0.07, 0.07);
    // meshText14.rotation.y = this.degToRad(90);
    // this.scene.add(meshText14);

    //
    var textureText15 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/texto16.png",
      (texture) => {}
    );

    var materialText15 = new THREE.MeshBasicMaterial({
      map: textureText15,
      transparent: true,
      opacity: 0.85,
    });

    var geometryText15 = new THREE.PlaneBufferGeometry(183, 146);
    var meshText15 = new THREE.Mesh(geometryText15, materialText15);
    meshText15.name = "text15";
    meshText15.position.set(113.5, 7.5, -11.1);
    meshText15.scale.set(0.07, 0.07, 0.07);
    meshText15.rotation.y = this.degToRad(90);
    this.scene.add(meshText15);

    //
    var textureText16 = new THREE.TextureLoader(this.manager).load(
      "../images/textos/pop-up.png",
      (texture) => {}
    );

    var materialText16 = new THREE.MeshBasicMaterial({
      map: textureText16,
      transparent: true,
      side: THREE.DoubleSide,
    });

    var geometryText16 = new THREE.PlaneBufferGeometry(183, 51);
    this.meshText16 = new THREE.Mesh(geometryText16, materialText16);
    this.meshText16.name = "pop-up";
    this.meshText16.position.set(6, 3, 65);
    this.meshText16.scale.set(0.08, 0.08, 0.08);
    this.meshText16.rotation.y = this.degToRad(180);
    this.scene.add(this.meshText16);
  };

  loadNavigationPlanes = () => {
    var textureArrow = new THREE.TextureLoader(this.manager).load(
      "../images/arrow.png",
      (texture) => {}
    );

    var materialArrow = new THREE.MeshBasicMaterial({
      map: textureArrow,
      transparent: true,
      opacity: 1,
    });

    var geometryArrow = new THREE.PlaneBufferGeometry(1.2, 2.6);

    var texture = new THREE.TextureLoader(this.manager).load(
      "../images/hotspot.png",
      (texture) => {}
    );

    var material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: 0.7,
    });

    var geometry = new THREE.PlaneBufferGeometry(4, 4);

    // 1
    this.nav01 = new THREE.Mesh(geometry, material);
    this.nav01.name = "nav01";
    this.nav01.position.set(200, -9.5, 22);
    this.nav01.rotation.x = this.degToRad(-90);
    this.scene.add(this.nav01);

    // 2
    this.nav02 = new THREE.Mesh(geometry, material);
    this.nav02.name = "nav02";
    this.nav02.position.set(160, -9.5, 35);
    this.nav02.rotation.x = this.degToRad(-90);
    this.nav02.scale.set(1.5, 1.5, 1.5);
    this.scene.add(this.nav02);

    // 3
    this.nav03 = new THREE.Mesh(geometry, material);
    this.nav03.name = "nav03";
    this.nav03.position.set(108, -9.5, 22);
    this.nav03.rotation.x = this.degToRad(-90);
    this.nav03.scale.set(1.5, 1.5, 1.5);
    this.scene.add(this.nav03);

    // 4
    // this.nav04 = new THREE.Mesh(geometry, material);
    // this.nav04.name = "nav04";
    // this.nav04.position.set(75, -9.5, 22);
    // this.nav04.rotation.x = this.degToRad(-90);
    // this.nav04.scale.set(1.5, 1.5, 1.5);
    // this.scene.add(this.nav04);

    // 5
    this.nav05 = new THREE.Mesh(geometry, material);
    this.nav05.name = "nav05";
    this.nav05.position.set(18, -9.5, 4);
    this.nav05.rotation.x = this.degToRad(-90);
    this.nav05.scale.set(1.5, 1.5, 1.5);
    this.scene.add(this.nav05);

    // 6
    this.nav06 = new THREE.Mesh(geometry, material);
    this.nav06.name = "nav06";
    this.nav06.position.set(0, -9.5, 22);
    this.nav06.rotation.x = this.degToRad(-90);
    this.nav06.scale.set(1.5, 1.5, 1.5);
    this.scene.add(this.nav06);

    // 7
    this.nav07 = new THREE.Mesh(geometry, material);
    this.nav07.name = "nav07";
    this.nav07.position.set(-75, -9.5, 0);
    this.nav07.rotation.x = this.degToRad(-90);
    this.nav07.rotation.z = this.degToRad(-43);
    this.nav07.scale.set(1.5, 1.5, 1.5);
    this.scene.add(this.nav07);

    // 8
    this.nav08 = new THREE.Mesh(geometry, material);
    this.nav08.name = "nav08";
    this.nav08.position.set(-145, -9.5, -28);
    this.nav08.rotation.x = this.degToRad(-90);
    this.nav08.scale.set(1.5, 1.5, 1.5);
    this.scene.add(this.nav08);

    // 9
    this.nav09 = new THREE.Mesh(geometry, material);
    this.nav09.name = "nav09";
    this.nav09.position.set(-175, -9.5, -36);
    this.nav09.rotation.x = this.degToRad(-90);
    this.scene.add(this.nav09);

    // 10
    // this.nav10 = new THREE.Mesh(geometry, material);
    // this.nav10.name = "nav10";
    // this.nav10.position.set(-207, -9.5, -26);
    // this.nav10.rotation.x = this.degToRad(-90);
    // this.scene.add(this.nav10);
  };

  degToRad = (deg) => {
    let radian = 2 * Math.PI * (deg / 360);
    return radian;
  };

  radToDeg = (rad) => {
    return rad * (180.0 / Math.PI);
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
    if (this.controls != null) {
      this.controls.update();
    }
    // this.stats.update();
    this.checkRaycastingMouseMove();
    if (this.camera && this.controls && this.showLookFichaBtn) {
      console.log("-----------");
      console.log(this.radToDeg(this.camera.rotation.y));
      var limit = this.isLandscapeMode() ? 60 : 75;
    }
  };

  checkRaycastingMouseMove = () => {
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    );
    if (intersects.length > 0) {
      //console.log(intersects);
      if (this.INTERSECTED != intersects[0].object) {
        this.INTERSECTED = intersects[0].object;
        if (
          this.INTERSECTED.name == "Suaje_Madera1" ||
          this.INTERSECTED.name == "pCube1" ||
          this.INTERSECTED.name == "Suaje_Madera2" ||
          this.INTERSECTED.name == "pCube2" ||
          this.INTERSECTED.name == "video01" ||
          this.INTERSECTED.name == "video02" ||
          this.INTERSECTED.name == "video03" ||
          this.INTERSECTED.name == "video04" ||
          this.INTERSECTED.name == "video05" ||
          this.INTERSECTED.name == "video06" ||
          this.INTERSECTED.name == "nav01" ||
          this.INTERSECTED.name == "nav02" ||
          this.INTERSECTED.name == "nav03" ||
          // this.INTERSECTED.name == "nav04" ||
          this.INTERSECTED.name == "nav05" ||
          this.INTERSECTED.name == "nav06" ||
          this.INTERSECTED.name == "nav07" ||
          this.INTERSECTED.name == "nav08" ||
          this.INTERSECTED.name == "nav09" ||
          this.INTERSECTED.name == "nav10" ||
          this.INTERSECTED.name == "door" ||
          this.INTERSECTED.name == "pop-up" ||
          (this.INTERSECTED.name == "book-tut-text" &&
            this.INTERSECTED.visible == true) ||
          (this.INTERSECTED.name == "book-tut-text2" &&
            this.INTERSECTED.visible == true)
        ) {
          this.setState({
            raycastCursorPointer: "pointer",
          });
        } else {
          this.setState({
            raycastCursorPointer: "default",
          });
        }
      }
    } else {
      if (this.INTERSECTED) {
        this.INTERSECTED = null;
      }
      this.setState({
        raycastCursorPointer: "default",
      });
    }
  };

  onWindowResize = () => {
    this.w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    if (this.renderer) {
      this.camera.aspect = this.w / this.h;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.w, this.h);
    }
  };

  onClickNext = (event) => {
    event.preventDefault();
    console.log(this.currentSection);
    if (this.currentSection < 8) {
      var nextSection = this.currentSection + 1;
      this.nextSectionFn(nextSection);
    }
  };

  nextSectionFn = (section = 1, duration = 5, hash = false) => {
    console.log(section);

    if (!hash) {
      if (this.tutorialIsHidden == false) {
        return;
      }
    }

    if (this.isAnimating == true) {
      return;
    }

    if (section == this.currentSection) {
      return;
    }

    this.previousSection = this.currentSection;
    this.currentSection = section;
    this.props["set-current-section"](section);

    var x = 0;
    var z = 0;
    var y = 0;
    var offsetX = 0;
    var offsetZ = 0;

    if (this.meshTutBook) {
      this.meshTutBook.visible = false;
    }

    if (this.meshTutBook2) {
      this.meshTutBook2.visible = false;
    }

    this.showLookFichaBtn = false;

    this.setState({
      lookFichaBtn: "none",
    });

    switch (section) {
      case 1:
        x = 200;
        z = 22;
        offsetX = 5;
        if (this.meshTutBook) {
          this.meshTutBook.visible = true;
        }
        // if (isMobile) {
        //   gsap.from(this.lookFichaBtn.current, {
        //     duration: 1,
        //     delay: 4,
        //     opacity: 0,
        //     onComplete: () => {
        //       this.showLookFichaBtn = true;
        //     },
        //   });
        //   this.setState({
        //     lookFichaBtn: "flex",
        //   });
        // }
        break;
      case 2:
        x = 160;
        z = 26;
        if (isMobile && !this.isLandscapeMode()) {
          offsetX = 0.6;
          offsetZ = -5;
        } else {
          offsetX = 2;
          offsetZ = -4.6;
        }
        break;
      case 3:
        x = 108;
        z = 22;
        offsetX = this.currentSection > this.previousSection ? 5 : -5;
        break;
      // case 4:
      //   x = 75;
      //   z = 22;
      //   if (isMobile && !this.isLandscapeMode()) {
      //     offsetX = 0.3;
      //     offsetZ = -5;
      //   } else {
      //     offsetX = 2;
      //     offsetZ = -4.6;
      //   }
      //   break;
      case 4:
        x = 18;
        z = 4;
        offsetX = 2;
        offsetZ = 4.6;
        y = 0.2;
        break;
      case 5:
        x = -10;
        z = 22;
        offsetZ = -5;
        break;
      case 6:
        x = -75;
        z = 0;
        offsetX = -2;
        offsetZ = 4.6;
        break;
      case 7:
        x = -145;
        z = -28;
        offsetZ = 5;
        break;
      case 8:
        x = -175;
        z = -36;
        // if (isMobile && !this.isLandscapeMode()) {
        offsetX = 5;
        // } else {
        //   offsetX = 4.28;
        //   offsetZ = -2.6;
        // }
        if (this.meshTutBook2) {
          this.meshTutBook2.visible = true;
        }
        // if (isMobile) {
        //   gsap.from(this.lookFichaBtn.current, {
        //     duration: 1,
        //     delay: 4,
        //     opacity: 0,
        //     onComplete: () => {
        //       this.showLookFichaBtn = true;
        //     },
        //   });
        //   this.setState({
        //     lookFichaBtn: "flex",
        //   });
        // }
        break;
      case 9:
        // x = -198;
        x = -207;
        z = -26;
        y = 0.2;
        offsetZ = -5;
        break;
    }

    this.rotateEspectadores();
    this.rotatePopUp();

    this.isAnimating = true;
    gsap.to(this.controls.target, {
      duration: duration,
      x: x,
      z: z,
      y: y,
      // ease: "power4.inOut",
      onUpdate: () => {
        this.controls.enableRotate = false;
      },
      onComplete: () => {
        this.controls.enableRotate = true;
        this.isAnimating = false;
      },
    });

    gsap.to(this.camera.position, {
      duration: duration,
      x: x + offsetX,
      z: z + offsetZ,
      y: y * -1,
      // ease: "power4.inOut",
      onUpdate: () => {
        this.controls.enableRotate = false;
      },
      onComplete: () => {
        this.controls.enableRotate = true;
        this.isAnimating = false;
      },
    });

    window.history.replaceState(
      {},
      document.title,
      window.location.href.split("#")[0] + "#" + section
    );

    this.gtmSendEvent(null, {
      event: "click",
      action_name: "gallery-frida-section-" + section + "-btn",
    });
  };

  rotateEspectadores = () => {
    if (this.currentSection == 1) {
      ////////////////////////////////////////// Section 1
    } else if (this.currentSection == 2) {
      ////////////////////////////////////////// Section 2
      gsap.to(this.espectadorWomanMesh02.rotation, {
        duration: 5,
        y: this.degToRad(95),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 3) {
      ////////////////////////////////////////// Section 3
      gsap.to(this.espectadorWomanMesh02.rotation, {
        duration: 5,
        y: this.degToRad(95),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 4) {
      ////////////////////////////////////////// Section 4
      gsap.to(this.espectadorWomanMesh02.rotation, {
        duration: 5,
        y: this.degToRad(115),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 5) {
      ////////////////////////////////////////// Section 5
      gsap.to(this.espectadorWomanMesh02.rotation, {
        duration: 5,
        y: this.degToRad(125),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 6) {
      ////////////////////////////////////////// Section 6
      gsap.to(this.espectadorWomanMesh02.rotation, {
        duration: 5,
        y: this.degToRad(240),
        // ease: "power4.inOut",
      });
      gsap.to(this.espectadorWomanMesh03.rotation, {
        duration: 5,
        y: this.degToRad(90),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 7) {
      ////////////////////////////////////////// Section 7
      gsap.to(this.espectadorWomanMesh03.rotation, {
        duration: 5,
        y: this.degToRad(90),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 8) {
      ////////////////////////////////////////// Section 8
      gsap.to(this.espectadorWomanMesh03.rotation, {
        duration: 5,
        y: this.degToRad(60),
        // ease: "power4.inOut",
      });
    } else if (this.currentSection == 9) {
      ////////////////////////////////////////// Section 9
      gsap.to(this.espectadorWomanMesh03.rotation, {
        duration: 5,
        y: this.degToRad(60),
        // ease: "power4.inOut",
      });
    }
  };

  rotatePopUp = () => {
    // if (this.currentSection <= 6) {
    //   gsap.to(this.meshText16.rotation, {
    //     duration: 5,
    //     y: this.degToRad(120),
    //   });
    // } else {
    //   gsap.to(this.meshText16.rotation, {
    //     duration: 5,
    //     y: this.degToRad(240),
    //   });
    // }
  };

  onClickPrev = (event) => {
    event.preventDefault();
    console.log(this.currentSection);
    if (this.currentSection > 1) {
      var nextSection = this.currentSection - 1;
      this.nextSectionFn(nextSection);
    }
  };

  onClickMenuBtn = (event) => {
    event.preventDefault();
    this.props["open-menu"]();
  };

  // onClickToogleAudiouBtn = (event) => {
  //   event.preventDefault();
  //   if (this.playback.current.paused) {
  //     this.playback.current.volume = 1;
  //     this.playback.current.currentTime = 0;
  //     this.playback.current.play();
  //     this.setState({
  //       audioBtnURL: on,
  //     });
  //     this.gtmSendEvent(null, {
  //       event: "click",
  //       action_name: "gallery-audio-on-btn",
  //     });
  //     Cookies.set("audio", 1);
  //   } else {
  //     this.playback.current.pause();
  //     this.setState({
  //       audioBtnURL: off,
  //     });
  //     this.gtmSendEvent(null, {
  //       event: "click",
  //       action_name: "gallery-audio-off-btn",
  //     });
  //     Cookies.set("audio", 0);
  //   }
  // };

  isHashValid = () => {
    if (
      this.hash == "#2" ||
      this.hash == "#3" ||
      this.hash == "#4" ||
      this.hash == "#5" ||
      this.hash == "#6" ||
      this.hash == "#7" ||
      this.hash == "#8"
    ) {
      return true;
    } else {
      return false;
    }
  };

  isLandscapeMode = () => {
    return window.orientation === 90 || window.orientation === -90;
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            cursor: this.state.raycastCursorPointer,
            backgroundColor: "#6aa2ec",
          }}
          ref={(mount) => {
            this.mount = mount;
          }}
        />
        {/* <div
          style={{
            position: "fixed",
            bottom: "0px",
            left: "0px",
            width: "189px",
            height: "165px",
            backgroundColor: "#f00",
          }}
        ></div> */}
        <a
          href="#"
          ref={this.prevButton}
          className="prev"
          onClick={this.onClickPrev}
        >
          <img src={prev} />
          <span>Anterior</span>
        </a>
        <a
          href="#"
          ref={this.nextButton}
          className="next"
          onClick={this.onClickNext}
        >
          <img src={next} />
          <span>Siguiente</span>
        </a>
        <a
          href="#"
          ref={this.chatButton}
          className="live-chat"
          onClick={(e) => {
            e.preventDefault();
            this.props["open-form"]();
            this.gtmSendEvent(e, {
              event: "click",
              action_name: "gallery-help-btn",
            });
          }}
        >
          <div className="icons-persons">
            <img src={person1} />
            <img src={person2} />
            <img src={person3} />
          </div>
          <span>
            Consigue<br></br> tu obra
          </span>
        </a>
        <a
          href="#"
          ref={this.lookFichaBtn}
          className="look-ficha-btn"
          style={{ display: this.state.lookFichaBtn }}
          onClick={(e) => {
            e.preventDefault();
            this.gtmSendEvent(e, {
              event: "view_content",
              content_name: "look-book-detail-btn",
            });
            this.controls.enableRotate = false;
            window.location.href = "/fridaficha/product.php";
          }}
        >
          <span>Conoce más sobre la obra</span>
        </a>
        <TutorialModal
          ref={this.tutorialModal}
          start-scene-controls={this.startSceneControls}
        ></TutorialModal>
        <ModalVideo
          ref={this.modalVideo}
          close-completion={this.onCloseModalVideoCompletion}
        ></ModalVideo>
        <a
          className="menu-artika"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.gtmSendEvent(e, {
              event: "click",
              action_name: "gallery-artika-btn",
            });
            window.location.href = "/";
          }}
        >
          <img className="artika-logo" src={artika}></img>
        </a>
        <a className="menu-hamburger" href="#" onClick={this.onClickMenuBtn}>
          <img className="home-menu" src={menu}></img>
        </a>
        <div className="libro-ar-btn">
          <a
            href={usdz}
            rel="ar"
            style={{ display: (isMobile && this.iOS && this.iOSSafari) ? "block" : "none" }}
          >
            <img className="image-model" src={libro} alt="" data-hires-status="pending" height="30"></img>
          </a>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Frida {...props} navigation={navigation} />;
}
