import React from "react";
import Cookies from "js-cookie";
import * as THREE from "three";
import { useNavigate } from "react-router-dom";
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
import GLTFLoader from "three-gltf-loader";
import DRACOLoader from "three-dracoloader";
import FBXLoader from "three-fbxloader-offical";
import OrbitControls from "three-orbitcontrols";
import { Raycaster as a, Vector2 as h, Vector3 } from "three";
import gsap from "gsap";
import { isMobile } from "detect-touch-device";
import sky from "../images/sky.jpg";
import TutorialModalHall from "../components/TutorialModalHall.js";
import ModalBotero from "../components/ModalBotero";
import ModalVideo from "../components/ModalVideo.js";
import artika from "../images/artika.png";
import menu from "../images/menu.png";
import off from "../images/off.svg";
import on from "../images/on.svg";
import person1 from "../images/person1.png";
import person2 from "../images/person2.png";
import person3 from "../images/person3.png";

class Hall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayAudioToggle: "block",
      audioBtnURL: off,
    };
    this.w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.manager = new THREE.LoadingManager();
    this.home = null;
    this.book = null;
    this.book2 = null;
    this.tutorialModalHall = React.createRef();
    this.modalBotero = React.createRef();
    this.pointer = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.mouseDown = false;
    this.touchStart = false;
    this.lastControlsPosition = null;
    this.tutorialIsHidden = false;
    this.toogleAudio = React.createRef();
    // this.playback = this.props["playback"];
    this.audioWasPlayingWhenOpenModalVideo = false;
    this.modalVideo = React.createRef();
  }

  componentDidMount() {
    console.log("component did mount");
    window.dataLayer.push({
      event: "pageview",
      pageName: "hall",
      pageType: "galeria virtual",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });

    this.props["set-loader-text-display"]("block");

    document.addEventListener("mousemove", this.onPointerMove);
    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("touchstart", this.onTouchStart);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("touchend", this.onTouchEnd);
    window.addEventListener("resize", this.onWindowResize, true);
    window.addEventListener("orientationchange", this.onWindowRotate, false);
    this.onWindowResize();

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.scene = new THREE.Scene();

    // Add Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    // this.renderer.setClearColor("#ffffff");
    this.renderer.setSize(width, height);
    // this.renderer.shadowMap.enabled = true;
    // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    this.mount.appendChild(this.renderer.domElement);

    // Add Camera
    this.camera = new THREE.PerspectiveCamera(42, width / height, 0.1, 1000);
    this.camera.position.x = 19.08;
    this.camera.position.y = 1.3;
    this.camera.position.z = 5;

    // Add Camera Controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.rotateSpeed *= isMobile ? -0.2 : -0.1;
    this.controls.target.set(19.08, 1.3, 4.9);
    this.controls.enableDamping = true;
    // this.controls.dampingFactor = 0.01;
    this.controls.enableZoom = false;
    this.controls.enableRotate = true;
    this.controls.enablePan = false;
    this.controls.maxPolarAngle = Math.PI / 1.6;
    this.controls.minPolarAngle = Math.PI / 2.2;
    this.controls.maxDistance = 2;
    this.controls.minDistance = 2;
    this.controls.update();

    // Add Lights
    this.ambientLight = new THREE.AmbientLightProbe(0xffffff, 1);
    this.scene.add(this.ambientLight);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.4);
    hemiLight.position.set(0, 28, 0);
    this.scene.add(hemiLight);

    // Start Scene
    this.renderScene();
    this.start();
    this.startLoaderManager();
  }

  onWindowRotate = () => {
    console.log("window rotate");
    this.updateCameraFov();
    this.updateObjects();
    this.onWindowResize();
    this.renderScene();
  };

  updateCameraFov = () => {
    if (isMobile) {
      if (this.isLandscapeMode()) {
        this.camera.fov = 35;
      } else {
        this.camera.fov = 45;
      }
    } else {
      this.camera.fov = 45;
    }
  };

  updateObjects = () => {
    if (isMobile) {
      if (this.isLandscapeMode()) {
        this.meshText01.position.set(19.1, 0.7, -3.7);
        this.meshText01.scale.set(1.2, 1.2, 1.2);

        this.meshText05.position.set(19.08, 2.5, -3.7);
        this.meshText05.scale.set(1.2, 1.2, 1.2);
      } else {
        this.meshText01.position.set(19.1, 0.9, -3.7);
        this.meshText01.scale.set(1, 1, 1);

        this.meshText05.position.set(19.08, 2.5, -3.7);
        this.meshText05.scale.set(1, 1, 1);
      }
    }
  };

  isLandscapeMode = () => {
    return window.orientation === 90 || window.orientation === -90;
  };

  componentWillUnmount() {
    this.stop();
    document.removeEventListener("mousemove", this.onPointerMove);
    document.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("touchstart", this.onTouchStart);
    document.removeEventListener("mouseup", this.onMouseUp);
    document.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("resize", this.onWindowResize, true);
    window.removeEventListener("orientationchange", this.onWindowRotate, false);
    this.dispose();
  }

  dispose = (e) => {
    this.scene.traverse((o) => {
      if (o.geometry) {
        o.geometry.dispose();
        console.log("dispose geometry ", o.geometry);
      }

      if (o.material) {
        if (o.material.length) {
          for (let i = 0; i < o.material.length; ++i) {
            o.material[i].dispose();
            console.log("dispose material ", o.material[i]);
          }
        } else {
          o.material.dispose();
          console.log("dispose material ", o.material);
        }
      }
    });

    this.renderer.dispose();
    this.museum = null;
    this.book = null;
    this.book2 = null;
    this.controls = null;
    this.camera = null;
    this.renderer = null;
    this.mount = null;
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  startLoaderManager = () => {
    this.manager.onStart = function (url, itemsLoaded, itemsTotal) {
      console.log();
    };

    this.manager.onLoad = () => {
      this.onWindowRotate();
      if (Cookies.get("tutorial") == 1) {
        this.tutorialIsHidden = true;
        this.tutorialModalHall.current.closeModal();
      }
      this.props["hide-loader"](2, 2);
    };

    this.manager.onProgress = function (url, itemsLoaded, itemsTotal) {
      console.log();
    };

    this.manager.onError = function (url) {};

    // this.loadSkyBox();
    this.loadHomeModel();
    this.loadBookModel();
    this.loadBookBoteroModel();
    this.loadTextPlanes();
    // this.createBoxBotero();
  };

  loadTextPlanes = () => {
    var textureText01 = new THREE.TextureLoader(this.manager).load(
      "./home/Artika_Sala_Principal.png",
      (texture) => {}
    );

    var materialText01 = new THREE.MeshBasicMaterial({
      map: textureText01,
      transparent: true,
      opacity: 1,
    });

    var geometryText01 = new THREE.PlaneBufferGeometry(4.02, 0.7);
    this.meshText01 = new THREE.Mesh(geometryText01, materialText01);
    this.meshText01.name = "text01";
    this.meshText01.position.set(19.1, 0.9, -3.7);
    this.meshText01.scale.set(1, 1, 1);
    this.meshText01.rotation.y = this.degToRad(0);
    this.scene.add(this.meshText01);

    //
    var textureText02 = new THREE.TextureLoader(this.manager).load(
      "./home/Copy_Frida.png",
      (texture) => {}
    );

    var materialText02 = new THREE.MeshBasicMaterial({
      map: textureText02,
      transparent: true,
      opacity: 1,
    });

    var geometryText02 = new THREE.PlaneBufferGeometry(1.8, 1.3);
    var meshText02 = new THREE.Mesh(geometryText02, materialText02);
    meshText02.name = "text02";
    meshText02.position.set(15, 1.1, 3);
    meshText02.scale.set(1, 1, 1);
    meshText02.rotation.y = this.degToRad(90);
    this.scene.add(meshText02);

    //
    var textureText03 = new THREE.TextureLoader(this.manager).load(
      "./home/Artika_Copy_Pared_Frida.png",
      (texture) => {}
    );

    var materialText03 = new THREE.MeshBasicMaterial({
      map: textureText03,
      transparent: true,
      opacity: 1,
    });

    var geometryText03 = new THREE.PlaneBufferGeometry(3.6, 2.7);
    var meshText03 = new THREE.Mesh(geometryText03, materialText03);
    meshText03.name = "text03";
    meshText03.position.set(15, 2, 6.7);
    meshText03.scale.set(1, 1, 1);
    meshText03.rotation.y = this.degToRad(90);
    this.scene.add(meshText03);

    //
    var textureText04 = new THREE.TextureLoader(this.manager).load(
      "./home/Artika_Copy_Pared_Botero.png",
      (texture) => {}
    );

    var materialText04 = new THREE.MeshBasicMaterial({
      map: textureText04,
      transparent: true,
      side: THREE.DoubleSide,
    });

    var geometryText04 = new THREE.PlaneBufferGeometry(3.6, 1.2);
    var meshText04 = new THREE.Mesh(geometryText04, materialText04);
    meshText04.name = "text04";
    meshText04.position.set(23.2, 1.8, 5.8);
    meshText04.scale.set(1, 1, 1);
    meshText04.rotation.y = this.degToRad(-90);
    this.scene.add(meshText04);

    //
    var textureText05 = new THREE.TextureLoader(this.manager).load(
      "./home/VideoHall.jpg",
      (texture) => {}
    );

    var materialText05 = new THREE.MeshBasicMaterial({
      map: textureText05,
      transparent: false,
    });

    var geometryText05 = new THREE.PlaneBufferGeometry(3.3, 2);
    this.meshText05 = new THREE.Mesh(geometryText05, materialText05);
    this.meshText05.name = "videoPlane";
    this.meshText05.position.set(19.08, 2.5, -3.7);
    this.meshText05.scale.set(1, 1, 1);
    this.meshText05.rotation.y = this.degToRad(0);
    this.scene.add(this.meshText05);

    //
    var textureText06 = new THREE.TextureLoader(this.manager).load(
      "./home/Artika1.png",
      (texture) => {}
    );

    var materialText06 = new THREE.MeshBasicMaterial({
      map: textureText06,
      transparent: true,
    });

    var geometryText06 = new THREE.PlaneBufferGeometry(1.6, 0.5);
    var meshText06 = new THREE.Mesh(geometryText06, materialText06);
    meshText06.name = "text06";
    meshText06.position.set(19.1, 0.3, 1);
    meshText06.scale.set(1, 1, 1);
    meshText06.rotation.x = this.degToRad(0);
    this.scene.add(meshText06);

    //
    var textureArrow = new THREE.TextureLoader(this.manager).load(
      "./images/hand_icon.png",
      (texture) => {}
    );

    var materialArrow = new THREE.MeshBasicMaterial({
      map: textureArrow,
      transparent: true,
    });

    var geometryArrow = new THREE.PlaneBufferGeometry(2.5, 1.5);

    this.navArrow01 = new THREE.Mesh(geometryArrow, materialArrow);
    this.navArrow01.name = "navArrow01";
    this.navArrow01.scale.set(0.2, 0.2, 0.2);
    this.navArrow01.position.set(15.6, 1.5, 1);
    this.navArrow01.rotation.z = this.degToRad(0);
    this.scene.add(this.navArrow01);

    this.navArrow01Anim = gsap.to(this.navArrow01.position, {
      duration: 0.6,
      x: 15.48,
      yoyo: true,
      repeat: -1,
      ease: "power1.inOut",
      onComplete: () => {},
    });

    //
    var materialArrow1 = new THREE.MeshBasicMaterial({
      map: textureArrow,
      transparent: true,
      side: THREE.DoubleSide,
    });

    var geometryArrow1 = new THREE.PlaneBufferGeometry(2.5, 1.5);

    this.navArrow02 = new THREE.Mesh(geometryArrow1, materialArrow1);
    this.navArrow02.name = "navArrow02";
    this.navArrow02.scale.set(0.2, 0.2, 0.2);
    this.navArrow02.position.set(22.6, 1.5, 1);
    this.navArrow02.rotation.z = this.degToRad(180);
    this.navArrow02.rotation.x = this.degToRad(180);
    this.scene.add(this.navArrow02);

    this.navArrow02Anim = gsap.to(this.navArrow02.position, {
      duration: 0.6,
      x: 22.72,
      yoyo: true,
      repeat: -1,
      ease: "power1.inOut",
      onComplete: () => {},
    });
  };

  loadSkyBox = () => {
    const loader = new THREE.TextureLoader(this.manager);
    const texture = loader.load(sky, () => {
      const rt = new THREE.WebGLCubeRenderTarget(texture.image.height);
      rt.fromEquirectangularTexture(this.renderer, texture);
      this.scene.background = rt.texture;
    });
  };

  loadHomeModel = () => {
    const loader = new FBXLoader(this.manager);
    loader.load("./home/Home.fbx", (object) => {
      this.home = object;
      this.home.scale.set(1, 1, 1);
      this.home.position.set(0, 0, 0);
      this.scene.add(this.home);
      this.loadHomeTextures();
    });
  };

  loadHomeTextures = () => {
    this.home.traverse((child) => {
      if (child.isMesh) {
        console.log(child.name);

        if (child.name == "Museo_Bancas_home2") {
          var texture01 = new THREE.TextureLoader(this.manager).load(
            "./home/Museo_Bancas.jpg",
            () => {
              child.material.map = texture01;
            }
          );
        } else if (child.name == "Museo_Bancas_home3") {
          var texture02 = new THREE.TextureLoader(this.manager).load(
            "./home/Museo_Bancas.jpg",
            () => {
              child.material.map = texture02;
            }
          );
        } else if (child.name == "Puerta_Botero") {
          var texture03 = new THREE.TextureLoader(this.manager).load(
            "./home/Puerta_Botero.jpg",
            () => {
              child.material.map = texture03;
            }
          );
        } else if (child.name == "Museo_Marco_botero") {
          var texture04 = new THREE.TextureLoader(this.manager).load(
            "./home/Museo_Bancas.jpg",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture04,
              });
              child.material = material;
            }
          );
        } else if (child.name == "Puerta_Frida") {
          var texture05 = new THREE.TextureLoader(this.manager).load(
            "./home/Puerta_Frida.jpg",
            () => {
              child.material.map = texture05;
            }
          );
        } else if (child.name == "Museo_Marco_frid") {
          var texture06 = new THREE.TextureLoader(this.manager).load(
            "./home/Museo_Bancas.jpg",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture06,
              });
              child.material = material;
            }
          );
        } else if (child.name == "Puerta_otro") {
          var texture07 = new THREE.TextureLoader(this.manager).load(
            "./home/Artika_Sala_principal_Puerta_Artika.jpg",
            () => {
              child.material.map = texture07;
            }
          );
        } else if (child.name == "Museo_MArco_otro") {
          var texture08 = new THREE.TextureLoader(this.manager).load(
            "./home/Museo_Bancas.jpg",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture08,
              });
              child.material = material;
            }
          );
        } else if (child.name == "Plano_frida") {
          var texture09 = new THREE.TextureLoader(this.manager).load(
            "./home/Frida.png",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture09,
                transparent: true,
                side: THREE.DoubleSide,
              });
              child.material = material;
            }
          );
        }
        // else if (child.name == "Plano_sumergete") {
        //   var texture10 = new THREE.TextureLoader(this.manager).load(
        //     "./home/Copy_Frida.png",
        //     () => {
        //       var material = new THREE.MeshBasicMaterial({
        //         map: texture10,
        //         transparent: true,
        //         side: THREE.DoubleSide,
        //       });
        //       child.material = material;
        //       child.material.map.minFilter = THREE.LinearFilter;
        //     }
        //   );
        // }
        else if (child.name == "Plano_Botero") {
          var texture11 = new THREE.TextureLoader(this.manager).load(
            "./home/Botero.png",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture11,
                transparent: true,
                side: THREE.DoubleSide,
              });
              child.material = material;
            }
          );
        } else if (child.name == "Home_Paredes_piso") {
          var texture12 = new THREE.TextureLoader(this.manager).load(
            "./home/Home_Paredes_piso.jpg",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture12,
              });
              child.material = material;
            }
          );
        } else if (child.name == "Home_Techo") {
          var texture13 = new THREE.TextureLoader(this.manager).load(
            "./home/Home_Paredes_piso.jpg",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture13,
                side: THREE.DoubleSide,
              });
              child.material = material;
            }
          );
        } else if (child.name == "Plano_articaBooks") {
          var texture14 = new THREE.TextureLoader(this.manager).load(
            "./home/Artika1.png",
            () => {
              var material = new THREE.MeshBasicMaterial({
                map: texture14,
                transparent: true,
                side: THREE.DoubleSide,
              });
              child.material = material;
            }
          );
        } else {
          child.visible = false;
        }
      }
    });
  };

  loadBookModel = () => {
    this.loader = new FBXLoader(this.manager);
    this.loader.load("./fbx/Libro_Frida_V2.fbx", (object) => {
      this.book = object;
      this.book.scale.set(0.04, 0.04, 0.04);
      this.book.position.set(16.86, 0.98, 1);
      this.book.rotation.x = this.degToRad(0);
      this.scene.add(this.book);
    });
  };

  loadBookBoteroModel = () => {
    this.loader1 = new FBXLoader(this.manager);
    this.loader1.load("./botero-files/Libro_Botero.fbx", (object) => {
      this.book2 = object;
      this.book2.scale.set(0.04, 0.04, 0.04);
      this.book2.position.set(21.34, 1.07, 1);
      this.book2.rotation.x = this.degToRad(0);
      this.scene.add(this.book2);
    });
  };

  createBoxBotero = () => {
    // var texture = new THREE.TextureLoader(this.manager).load(
    //   "./home/Cube.png",
    //   () => {

    //   }
    // );
    var material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0.7,
      // map: texture,
    });
    var geometry = new THREE.BoxGeometry(0.74, 0.47, 0.18);
    var mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(21.35, 0.98, 1);
    this.scene.add(mesh);
  };

  onPointerMove = (event) => {
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  onMouseDown = (event) => {
    if (this.controls == null) {
      return;
    }
    this.mouseDown = true;

    this.controls.saveState();
    this.lastControlsPosition = new Vector3(
      this.controls.position0.x,
      this.controls.position0.y,
      this.controls.position0.z
    );

    // if (this.firstTimeAudio == true && this.tutorialIsHidden) {
    //   this.firstTimeAudio = false;
    //   this.playback.current.volume = 1;
    //   this.playback.current.currentTime = 0;
    //   this.playback.current.play();
    //   this.setState({
    //     displayAudioToggle: "block",
    //     audioBtnURL: on,
    //   });
    // }
  };

  onTouchStart = (event) => {
    if (this.controls == null) {
      return;
    }
    this.touchStart = true;

    this.controls.saveState();
    this.lastControlsPosition = new Vector3(
      this.controls.position0.x,
      this.controls.position0.y,
      this.controls.position0.z
    );

    // if (this.firstTimeAudio == true && this.tutorialIsHidden) {
    //   this.firstTimeAudio = false;
    //   this.playback.current.volume = 1;
    //   this.playback.current.currentTime = 0;
    //   this.playback.current.play();
    //   this.setState({
    //     displayAudioToggle: "block",
    //     audioBtnURL: on,
    //   });
    // }
  };

  onMouseUp = (event) => {
    this.mouseDown = false;
    this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
    if (!isMobile) {
      this.checkRaycastingMouseDown();
    }
  };

  onTouchEnd = (event) => {
    this.touchStart = false;
    this.pointer.x =
      (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
    this.pointer.y =
      -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
    if (isMobile) {
      this.checkRaycastingMouseDown();
    } else if (!isMobile) {
      this.checkRaycastingMouseDown();
    }
  };

  checkRaycastingMouseDown = () => {
    if (this.props["menu"].current.isMenuOpened) {
      return;
    }
    if (this.tutorialIsHidden == false) {
      return;
    }
    if (this.props["form"].current.isFormOpened) {
      return;
    }
    if (this.controls == null) {
      return;
    } else {
      this.controls.saveState();
      if (
        this.controls.position0.distanceTo(this.lastControlsPosition) > 0.05
      ) {
        return;
      }
    }
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    );
    console.log(intersects);
    if (intersects.length > 0) {
      var first = intersects[0].object;

      if (first.name == "Puerta_Frida") {
        this.gtmSendEvent(null, {
          event: "click",
          action_name: "hall-frida-door-btn",
        });
        this.navArrow01Anim.pause();
        this.navArrow02Anim.pause();
        this.scene.remove(this.navArrow01);
        this.scene.remove(this.navArrow02);
        this.controls.enableRotate = false;
        this.controls = null;
        this.tutorialIsHidden = false;
        this.props["show-loader"](2.5, 0, "block", "/frida", () => {
          this.props.navigation("/frida/");
        });

        gsap.to(this.camera.position, {
          duration: 2,
          z: 1.8,
          x: 18,
          onUpdate: () => {},
          onComplete: () => {},
        });

        gsap.to(this.camera.rotation, {
          duration: 2,
          y: this.degToRad(75),
          onUpdate: () => {},
          onComplete: () => {},
        });
      } else if (first.name == "Puerta_Botero") {
        //this.modalBotero.current.openModal();
        this.gtmSendEvent(null, {
          event: "click",
          action_name: "hall-botero-door-btn",
        });
        this.navArrow01Anim.pause();
        this.navArrow02Anim.pause();
        this.scene.remove(this.navArrow01);
        this.scene.remove(this.navArrow02);
        this.controls.enableRotate = false;
        this.controls = null;
        this.tutorialIsHidden = false;
        this.props["show-loader"](2.5, 0, "block", "/botero", () => {
          this.props.navigation("/botero/");
        });

        gsap.to(this.camera.position, {
          duration: 2,
          z: 1.8,
          x: 20,
          onUpdate: () => {},
          onComplete: () => {},
        });

        gsap.to(this.camera.rotation, {
          duration: 2,
          y: this.degToRad(-75),
          onUpdate: () => {},
          onComplete: () => {},
        });
      } else if (first.name == "Puerta_otro") {
      } else if (first.name == "videoPlane") {
        // this.playbackPause();
        this.modalVideo.current.openModalVideoBox(
          "./videos/Artika_03b_ESP_MIX_211222_LOGO_v3.mp4"
        );
        this.gtmSendEvent(null, {
          event: "video",
          video_title: "Artika 03b ESP MIX 211222 LOGO v3",
        });
      }
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
    if (this.controls != null) {
      this.controls.update();
    }
    this.checkRaycastingMouseMove();
  };

  checkRaycastingMouseMove = () => {
    this.raycaster.setFromCamera(this.pointer, this.camera);
    const intersects = this.raycaster.intersectObjects(
      this.scene.children,
      true
    );
    if (intersects.length > 0) {
      //console.log(intersects);
      if (this.INTERSECTED != intersects[0].object) {
        this.INTERSECTED = intersects[0].object;
        if (
          this.INTERSECTED.name == "Puerta_Frida" ||
          this.INTERSECTED.name == "Puerta_Botero" ||
          this.INTERSECTED.name == "Puerta_otro" ||
          this.INTERSECTED.name == "videoPlane"
        ) {
          this.setState({
            raycastCursorPointer: "pointer",
          });
        } else {
          this.setState({
            raycastCursorPointer: "default",
          });
        }
      }
    } else {
      if (this.INTERSECTED) {
        this.INTERSECTED = null;
      }
      this.setState({
        raycastCursorPointer: "default",
      });
    }
  };

  componentDidUpdate() {
    console.log("component did update");
  }

  onWindowResize = () => {
    this.w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    if (this.renderer) {
      this.camera.aspect = this.w / this.h;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.w, this.h);
    }
  };

  degToRad = (deg) => {
    let radian = 2 * Math.PI * (deg / 360);
    return radian;
  };

  radToDeg = (rad) => {
    return rad * (180.0 / Math.PI);
  };

  startScene = () => {
    this.tutorialIsHidden = true;
    if (Cookies.get("tutorial") == 0 || Cookies.get("tutorial") == undefined) {
      Cookies.set("tutorial", 1);
    }
  };

  gtmSendEvent = (e, params) => {
    window.dataLayer.push(params);
  };

  onClickMenuBtn = (event) => {
    event.preventDefault();
    this.props["open-menu"]();
  };

  // onClickToogleAudiouBtn = (event) => {
  //   event.preventDefault();
  //   if (this.playback.current.paused) {
  //     this.playback.current.volume = 1;
  //     this.playback.current.currentTime = 0;
  //     this.playback.current.play();
  //     this.setState({
  //       audioBtnURL: on,
  //     });
  //     this.gtmSendEvent(null, {
  //       event: "click",
  //       action_name: "gallery-audio-on-btn",
  //     });
  //     Cookies.set("audio", 1);
  //   } else {
  //     this.playback.current.pause();
  //     this.setState({
  //       audioBtnURL: off,
  //     });
  //     this.gtmSendEvent(null, {
  //       event: "click",
  //       action_name: "gallery-audio-off-btn",
  //     });
  //     Cookies.set("audio", 0);
  //   }
  // };

  // playbackPause = () => {
  //   if (!this.playback.current.paused) {
  //     this.audioWasPlayingWhenOpenModalVideo = true;
  //   }
  //   this.playback.current.pause();
  //   this.setState({
  //     audioBtnURL: off,
  //   });
  // };

  onCloseModalVideoCompletion = () => {
    // if (this.audioWasPlayingWhenOpenModalVideo == true) {
    //   this.playback.current.volume = 1;
    //   this.playback.current.currentTime = 0;
    //   this.playback.current.play();
    //   this.setState({
    //     audioBtnURL: on,
    //   });
    // }
    // this.audioWasPlayingWhenOpenModalVideo = false;
  };

  render() {
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            cursor: this.state.raycastCursorPointer,
            backgroundColor: "#6aa2ec",
          }}
          ref={(mount) => {
            this.mount = mount;
          }}
        />
        <a
          className="menu-artika"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.gtmSendEvent(e, {
              event: "click",
              action_name: "hall-artika-btn",
            });
            window.location.href = "/";
          }}
        >
          <img className="artika-logo" src={artika}></img>
        </a>
        <a className="menu-hamburger" href="#" onClick={this.onClickMenuBtn}>
          <img className="home-menu" src={menu}></img>
        </a>
        <a
          href="#"
          ref={this.chatButton}
          className="live-chat"
          onClick={(e) => {
            e.preventDefault();
            this.props["open-form"]();
            this.gtmSendEvent(e, {
              event: "click",
              action_name: "hall-help-btn",
            });
          }}
        >
          <div className="icons-persons">
            <img src={person1} />
            <img src={person2} />
            <img src={person3} />
          </div>
          <span>
            Consigue<br></br> tu obra
          </span>
        </a>
        {/* <a
          ref={this.toogleAudio}
          className="menu-audio"
          href="#"
          onClick={this.onClickToogleAudiouBtn}
          style={{ display: this.state.displayAudioToggle }}
        >
          <img src={this.state.audioBtnURL}></img>
        </a> */}
        <ModalVideo
          ref={this.modalVideo}
          close-completion={this.onCloseModalVideoCompletion}
        ></ModalVideo>
        <TutorialModalHall
          ref={this.tutorialModalHall}
          start-scene-controls={this.startScene}
        ></TutorialModalHall>
        <ModalBotero ref={this.modalBotero}></ModalBotero>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Hall {...props} navigation={navigation} />;
}
