import React from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ReactDOM from "react-dom";
import Home from "./components/Home";
import Hall from "./components/Hall";
import Frida from "./components/Frida";
import Botero from "./components/Botero";
import FichaFrida from "./components/FichaTecnicaLibroFrida";
import "bootstrap/dist/css/bootstrap.min.css";
import Fonts from "./css/fonts.css";
import BoteroStyles from "./css/modal-botero.css";
import Styles from "./css/styles.css";
import { useSpring, animated } from "react-spring";
import { Controller } from "react-spring/renderprops";
import { Quart, Quint } from "gsap";
import Utils from "./helpers/Utils";
import Menu from "./components/Menu.js";
import Form from "./components/Form.js";
import Tycos from "./components/Tycos";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import DOMPurify from "dompurify";
import gsap from "gsap";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
import Cookies from "js-cookie";

const tagManagerArgs = {
  gtmId: "GTM-56HWXLQ",
};

TagManager.initialize(tagManagerArgs);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.loaders = ["loader1.gif", "botero-loader1.gif", "loader2.gif", "botero-loader2.gif", "loader3.gif", "botero-loader3.gif"];
    this.loaders1 = ["loader1.gif", "loader2.gif", "loader3.gif"];
    this.loaders2 = ["botero-loader1.gif", "botero-loader2.gif", "botero-loader3.gif", "botero-loader4.gif", "botero-loader5.gif", "botero-loader6.gif", "botero-loader7.gif"];
    this.state = {
      containerLoaderDisplay: "flex",
      loaderTextDisplay: "none",
      loaderImage: this.randomLoader(null),
    };
    this.containerLoader = React.createRef();
    // this.playback = React.createRef();
    this.menu = React.createRef();
    this.form = React.createRef();
    this.isCommingFromFicha = false;
    this.isCommingFromFichaSection = 0;
    this.currentSection = 0;
  }

  componentDidMount() {}

  randomLoader = (path = "/") => {
    if (path == null || path == undefined) {
      path = window.location.pathname;
    }
    var l;
    var maxLimit;
    switch (path) {
      case "/frida" || "/frida/":
        l = this.loaders1;
        maxLimit = this.loaders1.length;
        break;
      case "/botero" || "/botero/":
        l = this.loaders2;
        maxLimit = this.loaders2.length;
        break;
      default:
        l = this.loaders;
        maxLimit = this.loaders.length;
        break;
    }

    let rand = Math.random() * maxLimit;
    console.log(rand);
    rand = Math.floor(rand);

    if (Cookies.get("loader") == undefined) {
      Cookies.set("loader", l[rand]);
      return l[rand];
    } else if (Cookies.get("loader") == l[rand]) {
      return this.randomLoader(path);
    } else {
      Cookies.set("loader", l[rand]);
      return l[rand];
    }
  };

  hideLoader = (duration, delay) => {
    gsap.to(this.containerLoader.current, {
      duration: duration,
      opacity: 0,
      ease: "power4.inOut",
      delay: delay,
      onComplete: () => {
        this.setState({
          containerLoaderDisplay: "none",
        });
      },
    });
  };

  showLoader = (
    duration,
    delay,
    textDisplay = "none",
    path = "/",
    completion
  ) => {
    // console.log(image);
    this.setState({
      containerLoaderDisplay: "flex",
      loaderTextDisplay: textDisplay,
      loaderImage: this.randomLoader(path),
    });
    console.log("//////////////////");
    console.log(path);
    console.log(this.state.loaderImage);
    console.log("//////////////////");
    gsap.to(this.containerLoader.current, {
      duration: duration,
      opacity: 1,
      ease: "power4.inOut",
      delay: delay,
      onComplete: () => {
        if (completion) {
          completion();
        }
        // this.playback.current.pause();
      },
      // onUpdateParams: [this.playback],
      onUpdate: function () {
        // let volume = 1 - gsap.getProperty(this.targets()[0], "opacity");
        // playback.current.volume = volume;
      },
    });
  };

  openMenu = () => {
    this.menu.current.openMenu();
  };

  openForm = (type = 1) => {
    this.form.current.openForm(type);
  };

  setIsCommingFromFicha = (value) => {
    this.isCommingFromFicha = value;
  };

  setIsCommingFromFichaSection = (value) => {
    this.isCommingFromFichaSection = value;
  };

  getIsCommingFromFicha = () => {
    return this.isCommingFromFicha;
  };

  getIsCommingFromFichaSection = () => {
    return this.isCommingFromFichaSection;
  };

  setCurrentSection = (section) => {
    this.currentSection = section;
  };

  getCurrentSection = () => {
    return this.currentSection;
  };

  setLoaderTextDisplay = (value) => {
    this.setState({
      loaderTextDisplay: value,
    });
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Routes>
            {/* <Route
              exact
              path="/"
              element={
                <Home
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                />
              }
            ></Route> */}
            <Route
              exact
              path="/"
              element={
                <Hall
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  // playback={this.playback}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                  set-loader-text-display={this.setLoaderTextDisplay}
                />
              }
            ></Route>
            <Route
              exact
              path="/frida"
              element={
                <Frida
                  ref={this.galeriaFrida}
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  // playback={this.playback}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  set-is-comming-from-ficha-section={
                    this.setIsCommingFromFichaSection
                  }
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                  get-is-comming-from-ficha-section={
                    this.getIsCommingFromFichaSection
                  }
                  set-loader-text-display={this.setLoaderTextDisplay}
                  set-current-section={this.setCurrentSection}
                />
              }
            ></Route>
            <Route
              exact
              path="/frida/"
              element={
                <Frida
                  ref={this.galeriaFrida}
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  // playback={this.playback}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  set-is-comming-from-ficha-section={
                    this.setIsCommingFromFichaSection
                  }
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                  get-is-comming-from-ficha-section={
                    this.getIsCommingFromFichaSection
                  }
                  set-loader-text-display={this.setLoaderTextDisplay}
                  set-current-section={this.setCurrentSection}
                />
              }
            ></Route>
            <Route
              exact
              path="/botero"
              element={
                <Botero
                  ref={this.galeriaBotero}
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  // playback={this.playback}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  set-is-comming-from-ficha-section={
                    this.setIsCommingFromFichaSection
                  }
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                  get-is-comming-from-ficha-section={
                    this.getIsCommingFromFichaSection
                  }
                  set-loader-text-display={this.setLoaderTextDisplay}
                  set-current-section={this.setCurrentSection}
                />
              }
            ></Route>
            <Route
              exact
              path="/botero/"
              element={
                <Botero
                  ref={this.galeriaBotero}
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  // playback={this.playback}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  set-is-comming-from-ficha-section={
                    this.setIsCommingFromFichaSection
                  }
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                  get-is-comming-from-ficha-section={
                    this.getIsCommingFromFichaSection
                  }
                  set-loader-text-display={this.setLoaderTextDisplay}
                  set-current-section={this.setCurrentSection}
                />
              }
            ></Route>
            {/* <Route
              exact
              path="/frida/ficha"
              element={
                <FichaFrida
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  open-menu={this.openMenu}
                  open-form={this.openForm}
                  menu={this.menu}
                  form={this.form}
                  set-is-comming-from-ficha={this.setIsCommingFromFicha}
                  get-is-comming-from-ficha={this.getIsCommingFromFicha}
                  get-is-comming-from-ficha-section={
                    this.getIsCommingFromFichaSection
                  }
                />
              }
            ></Route> */}
          </Routes>
          <Menu
            ref={this.menu}
            open-form={this.openForm}
            get-current-section={this.getCurrentSection}
          ></Menu>
          <Form ref={this.form}></Form>
          <div
            ref={this.containerLoader}
            style={{ display: this.state.containerLoaderDisplay }}
            className="container-cargador"
          >
            <div
              className="loader-text"
              style={{ display: this.state.loaderTextDisplay }}
            >
              <img src={"../images/" + this.state.loaderImage}></img>
            </div>
            <span className="loader"></span>
          </div>
          {/* <audio loop ref={this.playback}>
            <source src="./audio/back.mp3" type="audio/mpeg"></source>
          </audio> */}
        </div>
      </Router>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
